'use strict';

import { convertToMMYYDDDD } from "@/utils/DateUtils";

export default class TrustdRequest {

  constructor() {
    this.GUEST_ID = 1234;
    this.RESERVATION_NO = null;
    this.FIRST_NAME = null;
    this.MIDDLE_NAME = null;
    this.LAST_NAME = null;
    this.DOB = null;
    this.GOVERNMENT_ISSUED_ID = null;
    this.CREDIT_SCORE = null;
    this.HOME_OWNERSHIP = null;
    this.ACADEMIC_DEGREE = null;
    this.CURRENTLY_EMPLOYED = null;
    this.YEARS_OF_EXPERIENCE = null;
    this.TYPE_OF_INCOME = null;
    this.BUSINESS_OWNER = null;
    this.PEOPLE_MANAGER = null;
    this.FINANCIAL_PLANNING = null;
    this.BEEN_IN_DEFAULT = null;
    this.AVERAGE_REFERENCE_SCORE = null;
    this.AVERAGE_HOST_FEEDBACK_SCORE = null;
    this.IP = null;
    this.HOST_EMAIL = null;
    this.GUEST_EMAIL = null;
    this.CHECK_IN_DATE = null;
    this.CHECK_OUT_DATE = null;
    this.GUEST_ADDRESS = null;
    this.PROPERTY_ADDRESS = null;
    this.HAS_LINKEDIN = null;
    this.PHONE = null;
    this.LISTING_NAME = null;
    this.LISTING_CHANNEL = null;
  }

  static create(guest, host) {
    const trustdRequest = new TrustdRequest();
    // guest data
    trustdRequest.GUEST_EMAIL = guest.email;
    trustdRequest.PHONE = guest.phone;
    trustdRequest.FIRST_NAME = guest.firstName;
    trustdRequest.MIDDLE_NAME = guest.middleName;
    trustdRequest.LAST_NAME = guest.lastName;
    trustdRequest.DOB = convertToMMYYDDDD(guest.birthDate);
    trustdRequest.CHECK_IN_DATE = convertToMMYYDDDD(guest.checkInDate);
    trustdRequest.CHECK_OUT_DATE = convertToMMYYDDDD(guest.checkOutDate);
    trustdRequest.LISTING_NAME = guest.listingName;
    trustdRequest.LISTING_CHANNEL = guest.listingChannel;

    // host data
    console.log('host data attributes: ', host.attributes , ', email: ', host.attributes.email);
    trustdRequest.HOST_EMAIL = host.attributes.email.toLowerCase();

    trustdRequest.cleanupNullFields();

    return trustdRequest;
  }

  cleanupNullFields() {
    // console.log('before clean-up object: ', this);

    this.cleanUpField("GUEST_ID");
    this.cleanUpField("RESERVATION_NO");
    this.cleanUpField("FIRST_NAME");
    this.cleanUpField("MIDDLE_NAME");
    this.cleanUpField("LAST_NAME");
    this.cleanUpField("DOB");
    this.cleanUpField("GOVERNMENT_ISSUED_ID");
    this.cleanUpField("CREDIT_SCORE");
    this.cleanUpField("HOME_OWNERSHIP");
    this.cleanUpField("ACADEMIC_DEGREE");
    this.cleanUpField("CURRENTLY_EMPLOYED");
    this.cleanUpField("YEARS_OF_EXPERIENCE");
    this.cleanUpField("TYPE_OF_INCOME");
    this.cleanUpField("BUSINESS_OWNER");
    this.cleanUpField("PEOPLE_MANAGER");
    this.cleanUpField("FINANCIAL_PLANNING");
    this.cleanUpField("BEEN_IN_DEFAULT");
    this.cleanUpField("AVERAGE_REFERENCE_SCORE");
    this.cleanUpField("AVERAGE_HOST_FEEDBACK_SCORE");
    this.cleanUpField("IP");
    this.cleanUpField("HOST_EMAIL");
    this.cleanUpField("GUEST_EMAIL");
    this.cleanUpField("CHECK_IN_DATE");
    this.cleanUpField("CHECK_OUT_DATE");
    this.cleanUpField("GUEST_ADDRESS");
    this.cleanUpField("PROPERTY_ADDRESS");
    this.cleanUpField("HAS_LINKEDIN");
    this.cleanUpField("PHONE");
    this.cleanUpField("LISTING_NAME");
    this.cleanUpField("LISTING_CHANNEL");

    // console.log('after clean-up object: ', this);
  }

  cleanUpField(field) {
    // console.log('check clean-up: ', field);
    if (this[field] == null) {
      // console.log('deleting: ', field);
      delete this[field];
    }
  }
}