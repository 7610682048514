'use strict';

const isEmpty = (inStr) => {
  if (inStr == null) {
    return true;
  }

  if (inStr.trim() === '') {
    return true;
  }

  return false;
}


module.exports = {
  isEmpty,
}