<template>
    <div v-if="loading" class="loading-div">
        <ProgressSpinner style="width: 150px; height: 150px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" />
    </div>
    <div v-else class="submitInformation">
        <Card class="cardCenter cardW50 submitInformationCard">
            <template #title>
                Create Invitation Code
            </template>
            <template #content>
                <div class="mb-2 row">
                    <div class="col-md-4 col-sm-12 d-flex align-items-center">
                        <label for="invitationCode" class="form-label">Invitation Code<sup class="validatedField">*</sup> <span @click="makeRandomCode">(Dice)</span></label>
                    </div>
                    
                    <div class="col-md-8 col-sm-12">
                        <input type="text" class="form-control" id="invitationCode" placeholder="Invitation Code"
                            v-model="invitationCode">
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-md-4 col-sm-12 d-flex align-items-center">
                        <label for="channelDropdown" class="form-label">Channel<sup class="validatedField">*</sup></label>
                    </div>
                    <div class="col-md-8 col-sm-12">
                        <Dropdown v-model="selectedChannel" :options="channels" optionLabel="name" optionValue="value"
                                placeholder="Select a Channel" id="channelDropdown" class="form-select p-1"
                                scrollHeight="300px"/>
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-md-4 col-sm-12 d-flex align-items-center">
                        <label for="description" class="form-label">Description</label>
                    </div>
                    
                    <div class="col-md-8 col-sm-12">
                        <input type="text" class="form-control" id="description" placeholder="Description"
                            v-model="description">
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-md-4 col-sm-12 d-flex align-items-center">
                        <label for="notes" class="form-label">Notes</label>
                    </div>
                    
                    <div class="col-md-8 col-sm-12">
                        <input type="text" class="form-control" id="notes" placeholder="Notes"
                            v-model="notes">
                    </div>
                </div>
                <div class="mb-2 row">
                    <div class="col-md-4 col-sm-12 d-flex align-items-center">
                        <label for="password" class="form-label">Password</label>
                    </div>
                    
                    <div class="col-md-8 col-sm-12">
                        <input type="text" class="form-control" id="password" placeholder="Password"
                            v-model="password">
                    </div>
                </div>
                <Button label="Create Code" @click="createCode" />
            </template>
        </Card>
    </div>

    <terms-and-conditions ref="termsAndConditions"></terms-and-conditions>
</template>

<script>
import { ref } from "vue";

import axios from 'axios';

export default {
    name: "InvitationCode",
    data() {
        return {
            invitationCode: '',
            selectedChannel: ref(),
            notes: '',
            description: '',
            password: '',
            channels: ref([
                { name: 'Hostaway', value: 'hostaway' },
                { name: 'Ownerrez', value: 'ownerrez' },
                { name: 'Trustd', value: 'trustd' },
            ])
        }
    },
    async mounted() {
        
    },
    methods: {  
        makeRandomCode() {
            this.invitationCode = this.generateRandomString(16);
        },
        generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        async createCode() {
            try {
                await axios.post('/tpc/api/invitation-code/create', {
                    code: this.invitationCode,
                    channel: this.selectedChannel,
                    notes: this.notes,
                    description: this.description,
                    password: this.password,
                });
                
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Invitation Code Created Succesfully!', life: 5000 });

            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Incorrect password!', life: 10000 });
            }
        }
    }
}
</script>

<style scoped>
.terms-and-conditions {
    cursor: pointer;
    color: #6366F1;
}

.submitInformation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

.submitInformationText {
    text-align: center;
    font-weight: 700;
    font-size: large;
}

.mt-0 {
    margin-top: 0;
}

.loading-div {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 60px);
}

.welcome-text {
  font-size: medium;
  font-weight: 400;
}

</style>