'use strict';

export default class TrustdPhoneLookup {

  constructor() {
    this.phoneNumber = null;
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
    this.city = null;
    this.state = null;
    this.zip = null;
//    this.fullAddress = null;
    this.carrier = null;
    this.phoneType = null;
    this.timeZone = null;
    this.lastReportedDate = null;
  }

  static create(rawData) {
    let data = JSON.parse(JSON.stringify(rawData));
    console.log('data', data);

    const response = new TrustdPhoneLookup();
    response.phoneNumber = data.phoneNumber;
    response.firstName = data.firstName;
    response.middleName = data.middleName;
    response.lastName = data.lastName;
    response.city = data.city;
    response.state = data.state;
    response.zip = data.zip;
//    response.fullAddress = data.fullAddress;
    response.carrier = data.carrier;
    response.phoneType = data.phoneType;
    response.timeZone = data.timeZone;
    response.lastReportedDate = data.lastReportedDate;

    return response;
  }
}
