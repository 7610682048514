"use strict";

const { Auth } = require("aws-amplify");
const { findCountryCode } = require("@/utils/CountryCodes");

/**
 * signIn
 *
 * @param {String} username
 * @param {String} password
 * @return {Object | Promise<*>}
 */
const signIn = async (username, password) => {
  console.log("AmplifyAuth:signIn username: ", username, " password: *");
  const authenticatedUser = await Auth.signIn(username, password);

  localStorage.setItem("asdasd123123dfgdfgqweqwe", "true");
  console.log(
    "AmplifyAuth:signIn authenticatedUser: ",
    username,
    " password: *"
  );
  return authenticatedUser;
};

const sendEmailVerificationCode = async (email) => {
  try {
    // E-posta doğrulama kodu gönder
    await Auth.resendSignUp(email);
    console.log("Verification code sent via email.");
  } catch (error) {
    console.error("Error sending email verification code:", error);
  }
};

/**
 * signOut
 *
 * @return {Promise<void>}
 */
const signOut = async () => {
  console.log("AmplifyAuth:signOut");
  await Auth.signOut();
  console.log("AmplifyAuth:signOut success");
};

/**
 * currentAuthenticatedUser
 * @return {Object | Promise<any>}
 */
const currentAuthenticatedUser = async () => {
  console.log("AmplifyAuth:currentAuthenticatedUser");
  let user = await Auth.currentAuthenticatedUser();

  if (!user) {
    window.location.href = "/login";
    localStorage.removeItem("asdasd123123dfgdfgqweqwe");
  }

  console.log("AmplifyAuth:currentAuthenticatedUser user: ", user);
  return user;
};

/**
 * currentAuthenticatedUserRefresh
 * @return {Object | Promise<any>}
 */
const currentAuthenticatedUserRefresh = async () => {
  console.log("AmplifyAuth:currentAuthenticatedUserRefresh");
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  });
  console.log("AmplifyAuth:currentAuthenticatedUserRefresh user: ", user);
  return user;
};

/**
 * currentAuthenticatedUserAttributes
 * @return {Object | Promise<any>}
 */
const currentAuthenticatedUserAttributes = async () => {
  console.log("AmplifyAuth:currentAuthenticatedUserAttributes");
  await currentAuthenticatedUserRefresh();
  const authenticatedUser = await currentAuthenticatedUser();
  const phoneWithoutInternationalCode = resolvePhone(authenticatedUser);
  const user = {
    username: authenticatedUser.attributes.email,
    password: "********",
    contactName:
      authenticatedUser.attributes.name != null
        ? authenticatedUser.attributes.name
        : "",
    company:
      authenticatedUser.attributes["custom:company"] != null
        ? authenticatedUser.attributes["custom:company"]
        : "",
    country:
      authenticatedUser.attributes["custom:country"] != null
        ? authenticatedUser.attributes["custom:country"]
        : "",
    phone: phoneWithoutInternationalCode,
    email:
      authenticatedUser.attributes.email != null
        ? authenticatedUser.attributes.email
        : "",
    website:
      authenticatedUser.attributes.website != null
        ? authenticatedUser.attributes.website
        : "",
    phoneNumber:
      authenticatedUser.attributes.phone_number != null
        ? authenticatedUser.attributes.phone_number
        : "",
    address:
      authenticatedUser.attributes.address != null
        ? authenticatedUser.attributes.address
        : "",
    sendEmail:
      authenticatedUser.attributes["custom:sendEmail"] != null
        ? authenticatedUser.attributes["custom:sendEmail"]
        : "NO",
    channel:
      authenticatedUser.attributes["custom:channel"] != null
        ? authenticatedUser.attributes["custom:channel"]
        : "",
    /*
            birthdate: authenticatedUser.attributes.birthdate,
            family_name: authenticatedUser.attributes.family_name,
            gender: authenticatedUser.attributes.gender,
            given_name: authenticatedUser.attributes.given_name,
            locale: authenticatedUser.attributes.locale,
            middle_name: authenticatedUser.attributes.middle_name,
            nickname: authenticatedUser.attributes.nickname,
            picture: authenticatedUser.attributes.picture,
            preferred_username: authenticatedUser.attributes.preferred_username,
            profile: authenticatedUser.attributes.profile,
            updated_at: authenticatedUser.attributes.updated_at,
            zoneinfo: authenticatedUser.attributes.zoneinfo,
    */
  };
  console.log("AmplifyAuth:currentAuthenticatedUserAttributes user: ", user);
  return user;
};

const resolvePhone = (authenticatedUser) => {
  if (
    authenticatedUser.attributes["custom:country"] != null &&
    authenticatedUser.attributes.phone_number != null
  ) {
    const countryName = authenticatedUser.attributes["custom:country"];
    const phoneNumber = authenticatedUser.attributes.phone_number;

    const countryCode = findCountryCode(countryName);

    if (countryCode != null) {
      const prefix = "+" + countryCode.phoneCode;
      if (phoneNumber.startsWith(prefix)) {
        const phoneWithoutInternationalCode = phoneNumber.substring(
          prefix.length
        );
        console.log(
          phoneNumber +
            " without international code: " +
            phoneWithoutInternationalCode
        );
        return phoneWithoutInternationalCode;
      }
    }
  }

  return null;
};

/**
 * updateUserAttributes
 * @param user
 * @param userAttributes
 * @return {Promise<void>}
 */
const updateUserAttributes = async (user, userAttributes) => {
  console.log("AmplifyAuth:updateUserAttributes attributes: ", userAttributes);
  const result = Auth.updateUserAttributes(user, {
    name: userAttributes.contactName,
    "custom:company": userAttributes.company,
    "custom:country": userAttributes.country,
    website: userAttributes.website,
    phone_number: userAttributes.phoneNumber,
    address: userAttributes.address,
    "custom:sendEmail": userAttributes.sendEmail,
    "custom:channel": userAttributes.channel,
  });
  console.log("AmplifyAuth:updateUserAttributes result: ", result);
  return user;
};

/**
 * currentUserInfo
 * @return {Object | Promise<any>}
 */
const currentUserInfo = async () => {
  console.log("AmplifyAuth:currentUserInfo");
  const user = await Auth.currentUserInfo();
  console.log("AmplifyAuth:currentUserInfo user: ", user);
  return user;
};

/**
 * currentUserPoolUser
 * @return {Object | Promise<any>}
 */
const currentUserPoolUser = async () => {
  console.log("AmplifyAuth:currentUserPoolUser");
  const user = await Auth.currentUserPoolUser();
  console.log("AmplifyAuth:currentUserPoolUser user: ", user);
  return user;
};

/**
 * currentCredentials
 * @return {Object | Promise<any>}
 */
const currentCredentials = async () => {
  console.log("AmplifyAuth:currentCredentials");
  const credentials = await Auth.currentCredentials();
  console.log("AmplifyAuth:currentCredentials credentials: ", credentials);
  return credentials;
};

/**
 * essentialCredentials
 * @return {Object | Promise<any>}
 */
const essentialCredentials = async (credentials) => {
  console.log("AmplifyAuth:essentialCredentials");
  const essentialCredentials = await Auth.essentialCredentials(credentials);
  console.log(
    "AmplifyAuth:essentialCredentials essentialCredentials: ",
    essentialCredentials
  );
  return essentialCredentials;
};

/**
 * userSession
 * @return {Object | Promise<any>}
 */
const userSession = async (user) => {
  console.log("AmplifyAuth:userSession");
  const session = await Auth.userSession(user);
  console.log("AmplifyAuth:userSession session: ", session);
  return session;
};

/**
 * currentSession
 * @return {Object | Promise<any>}
 */
const currentSession = async () => {
  console.log("AmplifyAuth:currentSession");
  const session = await Auth.currentSession();
  console.log("AmplifyAuth:currentSession session: ", session);
  return session;
};

/**
 * federatedSignIn
 */
const federatedSignIn = async (provider) => {
  console.log("AmplifyAuth:federatedSignIn with provider: ", provider);
  // Auth.federatedSignIn({provider: provider}); // todo not implemented
  console.log("Not Implemented");
};

/**
 * forgotPassword
 *
 * @param {String} username
 */
const forgotPassword = async (username) => {
  console.log("AmplifyAuth:forgotPassword username: ", username);
  await Auth.forgotPassword(username);
  console.log("AmplifyAuth:signIn forgotPassword called ");
};

/**
 * forgotPasswordSubmit
 *
 * @param {String} username
 * @param {String} code
 * @param {String} newPassword
 */
const forgotPasswordSubmit = async (username, code, newPassword) => {
  console.log(
    "AmplifyAuth:forgotPasswordSubmit username: ",
    username,
    "code: ",
    code,
    "newPassword: *"
  );
  await Auth.forgotPasswordSubmit(username, code, newPassword);
  console.log("AmplifyAuth:signIn forgotPasswordSubmit called ");
};

/**
 * changePassword
 *
 * @param {Object} user
 * @param {String} oldPassword
 * @param {String} newPassword
 */
const changePassword = async (user, oldPassword, newPassword) => {
  console.log("AmplifyAuth:changePassword: oldPassword: *, newPassword: *");
  await Auth.changePassword(user, oldPassword, newPassword);
  console.log("AmplifyAuth:changePassword called");
};

/**
 * completeNewPassword
 *
 * @param {Object} user
 * @param {String} newPassword
 */
const completeNewPassword = async (user, newPassword) => {
  console.log(
    "AmplifyAuth:completeNewPassword: user: ",
    user,
    ", newPassword: *"
  );
  await Auth.completeNewPassword(user, newPassword);
  console.log("AmplifyAuth:completeNewPassword called");
};

/**
 * signUp
 *
 * @param signUpAttributes
 * @return {Promise<void>}
 */
const signUp = async (signUpAttributes) => {
  console.log("AmplifyAuth:signUp attributes: ", signUpAttributes);
  const result = Auth.signUp({
    username: signUpAttributes.username,
    password: signUpAttributes.password,
    attributes: {
      name: signUpAttributes.contactName,
      "custom:company": signUpAttributes.company,
      "custom:country": signUpAttributes.country,
      website: signUpAttributes.website,
      phone_number: signUpAttributes.phoneNumber,
      address: signUpAttributes.address,
      "custom:sendEmail": signUpAttributes.sendEmail,
      "custom:channel": signUpAttributes.channel,
    },
  });
  console.log("AmplifyAuth:signUp result: ", result);
  return result;
};

/**
 * Confirms signup with verification code
 *
 * @param username username / email address
 * @param verificationCode verification code provided in the email
 */
const confirmSignUp = async (username, verificationCode) => {
  await Auth.confirmSignUp(username, verificationCode);
};

module.exports = {
  signIn,
  signOut,
  currentAuthenticatedUser,
  currentAuthenticatedUserAttributes,
  updateUserAttributes,
  currentUserInfo,
  currentUserPoolUser,
  currentCredentials,
  essentialCredentials,
  userSession,
  currentSession,
  federatedSignIn,
  forgotPassword,
  forgotPasswordSubmit,
  changePassword,
  completeNewPassword,
  signUp,
  confirmSignUp,
  sendEmailVerificationCode,
};
