import Calendar from 'primevue/calendar'

import { defineComponent } from 'vue';

export default defineComponent({
    extends: Calendar,
    methods: {
        updateFocus: () => {
            /* Prevent primevue's default behavior. So date component accepts manuel inputs as well. */
        }
    },
});