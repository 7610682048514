<template>
  <Dialog header="Service Agreement (scroll down to close dialog)" v-model:visible="displayServiceAgreement"
          :style="{width: '50vw'}"
          :modal="true" :closable="false" :closeOnEscape="false">

    <div style="max-height: 75%; scroll-behavior: auto;">
      <h2 autofocus>Guest Screening and Background Check Service Agreement</h2>

      <p><strong>Company:</strong> Sovrign, Inc., also known as trustd.ai, a company organized and existing under the laws of NJ, with its
principal place of business at 101 Crawfords Corner Rd, Suite 4-116, Holmdel, NJ 07733 ("Company"),
</p>

      <p><strong>Customer:</strong> A Property Manager, Short Term Rental Host, Property Owner, or other entity utilizing Sovrign, Inc.'s services ("Customer").
      </p>

      <h3>1. The Scope of Contract</h3>

      <p>Company, operating under the trade name trustd.ai, provides specialized guest screening and background check services ("Services") tailored for property managers, short-term rental hosts, property owners, and similar entities. These Services encompass the generation of comprehensive reports containing background screening results and a Trustd Score metric. To facilitate these screenings, the Company employs publicly accessible data sources, including but not limited to criminal databases, arrest records, sex offender registries, international watch lists, reverse phone lookups, and address verification. In addition, Company conducts thorough analysis of guest booking data, property addresses, IP addresses, and other pertinent screening criteria.</p>
      <p>The Services are hosted on the infrastructure of Amazon Web Services (AWS) and are designed for seamless integration with Property Management Systems, Booking Systems, and Channel Managers through an Application Programming Interface (API). Company reserves the right to utilize all or part of the aforementioned information to ensure efficient delivery of its Services.</p>
      <p>The Company ensures prompt delivery of its Services in near real-time; however, Company shall not be held liable for any delays arising from processing, network issues, computing delays, or delays attributable to third-party systems.</p>

      <h3>2. Pricing</h3>

      <p><strong>Service Fee:</strong> The Company offers Screening Credit packages to customers upon opening an account in the Trustd.ai system. Customers may select a package with a specified number of Screening Credits corresponding to their booking requirements. Customers agree to pay a nonrefundable service fee for each selected top-up package. A receipt of the transaction will be provided to the Customer following each top-up charge. Top-up charges are not automatic, the Customer needs to manually top-up the account to prevent service interruptions.
      </p>

      <p>The Company operates its services on AWS and maintains an infrastructure to ensure real-time service delivery. Regardless of utilization, ongoing platform costs are incurred by the Company to maintain service availability in real-time. Customers acknowledge and accept that the service charge is non-refundable.
      </p>

      <p><strong>Modification of Pricing and Promotions:</strong> The Company reserves the right to change, alter, or modify service pricing at its discretion, including the cancellation of existing pricing models, introduction of new pricing models, or the initiation and termination of promotions, without prior notice to customers. Customers hereby waive any right to pursue legal action against the Company arising from such pricing adjustments or disparities.
      </p>
    
      <p><strong>Promotional Offers:</strong> Customers acknowledge and accept that any Free Screening or promotional offer does not entitle them to earn additional free credits or services beyond what is explicitly outlined in the promotion.</p>
    
      <h3>3. Limitation of Liability</h3>

      <p>The Company shall not be liable for any inaccuracies, errors, or omissions in the Services provided, including data obtained from publicly available sources, which may be inherently flawed, incomplete, or outdated. The Customer
        further acknowledges that the Company acts solely as a facilitator of information and does not assume responsibility for the conduct, actions, or any potential liabilities of guests or hosts within the Short-Term Rental (STR) ecosystem.</p>

      <p>Customers agree to indemnify and hold harmless the Company, its officers, directors, employees, and agents from any claims, liabilities, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising from the use of the Services, breach of this Agreement, or violation of any rights of third parties.</p>

      <p>The provisions regarding indemnification and limitations of liability shall remain effective even after termination of this Agreement.</p>

      <h3>4. Guest Reviews</h3>

      <p>Customers may be requested to provide guest reviews on the trustd.ai platform. By submitting reviews, customers acknowledge and agree to the following terms:</p>

      <ul>
        <li>Reviews are intended to enhance trust and safety within the Short-Term Rental (STR) community.</li>
        <li>Reviews must be honest, factual, and relevant to the specific stay, focusing on the guest's behavior and its
          impact on the property.</li>
        <li>Reviews should refrain from personal attacks, discriminatory language, or irrelevant personal opinions.</li>
        <li>The Company does not mediate or facilitate direct communication between hosts and guests based on
          reviews. Customers are solely responsible for any disputes arising from reviews.</li>
        <li>Upon request, the Company may make reviews available to the guest.</li>
        <li>Customers understand that reviews are public and accessible to other hosts and guests within the STR
          community.</li>
      </ul>

      <h3>5. Interpretation of Trustd Score</h3>

      <p>The Customer acknowledges and agrees that the Trustd Score provided by Company reflects only guest behavioral patterns specifically within the Short-Term Rental (STR) ecosystem. The Trustd Score is designed to assist hosts and property managers in evaluating potential guests based on their behavior within this specific context. It is not intended to serve as a general trust score applicable outside the STR segment or to be the sole determinant of guest trustworthiness.
      </p>
      <p>Initially, new guests are assigned a default (good) score. This default score is subject to adjustment over time based on ongoing behavioral data collected by Company. The Trustd Score may fluctuate as additional information becomes available, reflecting changes in a guest's conduct within the STR community.
      </p>
      <p>The Customer further acknowledges that while the Trustd Score provides valuable insights, it does not guarantee the character, integrity, or reliability of any individual guest. It is recommended that hosts and property managers use the Trustd Score in conjunction with other relevant information and their own judgment when making decisions related to guest screening and acceptance.</p>

      <h3>6. Booking Cancellations Due to Trustd.ai Score</h3>

      <p>If the Customer decides to cancel the reservation based on the Company’s screening result, the Company shall not be held liable for the Customer decisions and the Customer is required to comply with the cancellation policies specified by the listing platform where the property is listed. The Company suggests that the Customer informs the guest about the decision of cancellation, and a copy of the result shall be provided to the guest for full transparency. The Company assumes no responsibility for any losses, damages, or liabilities arising from customer cancellations, which remain solely the responsibility of the customer as per the terms outlined by the respective listing platform.</p>

      <h3>7. Termination of Agreement</h3>

      <p>The Customer has the right to terminate this agreement at any time by cancelling the service through the host portal, without incurring any further obligations or liabilities.</p>
      <p>The Company reserves the right to terminate this Agreement at its discretion and without providing a reason, should the Company's business circumstance or operational needs necessitate such termination. The Company shall provide written notice to the Customer, and the termination shall be effective immediately.</p>
      <p>Additionally, the Company reserves the right to terminate this Agreement if, in its sole discretion and judgment, the Customer engages in activities that violate platform rules, review policies, or conduct deemed non-ethical and outside the established scope of business activities. Such activities may include, but are not limited to, fraudulent behavior, misuse of the platform's features, or actions that significantly undermine the trust and integrity upon which the Company's services are built. Termination under this provision shall be at the Company's discretion and effective immediately.
      </p>

      <h3>8. Governing Law and Jurisdiction</h3>

      <p>
        This Agreement shall be governed by the laws of the State of New Jersey, USA, without regard to its conflict of law principles. Any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity, or termination, shall be subject to the exclusive jurisdiction of the state and federal courts located in New Jersey, USA.</p>

      <br>

      <p>Signature: The Customer approved this AGREEMENT ELECTRONICALLY during the Sign Up process.</p>

      <br><br>
    </div>

    <div>
      <Button label="I've read, understood and accepted!" icon="pi pi-check" @click="closeServiceAgreemet"
              autofocus/>
    </div>

  </Dialog>

  <Dialog header="Terms and Conditions (scroll down to close dialog)" v-model:visible="displayTermsAndConditions"
          :style="{width: '50vw'}"
          :modal="true" :closable="false" :closeOnEscape="false">

    <div style="max-height: 75%; scroll-behavior: auto;">
      <h2 autofocus>Trustd.ai’s Terms of Use</h2>

      <p>These Terms of Use ("Terms" or "Agreement") govern your rights and obligations regarding the use of Trustd.ai
        services and sites, which is owned by Sovrign Inc., a New Jersey company (collectively referred to as the
        "Service" or "Site" or "Trustd.ai") on the Internet. These Terms constitute a fully binding agreement between
        Trustd.ai (including its affiliates and subsidiaries "We") the proprietor of all rights in and to the Service,
        and
        you. You shall be defined as individual users, companies or organizations who may use our Service. In the event,
        the user is a company or organization, you agree to provide the requisite education of the Service within your
        company or organization. It is therefore recommended that you carefully read these Terms.</p>

      <p>By using Trustd.ai’s Service, you signify your assent to these Terms and our Privacy Policy ("Privacy Policy")
        all of which are an integral part of these Terms. If you do not agree to these Terms or any of its parts, then
        you
        are prohibited from using the Service and we request that you refrain from doing so.</p>

      <h3>Key Points</h3>
      <p>The following key points of the Terms of Use are brought for your convenience only. These key points are not in
        lieu of the full Terms of Use.</p>
      <ul>
        <li>You agree to hold the Site harmless from any damages that may result from any and all information available
          through our Site.
        </li>
        <li>You agree to hold the Site harmless from any legal or equitable disputes that may be derived or caused by
          any
          and all information available through our Site.
        </li>
        <li>You acknowledge and understand that some of the information available to you on our Site is collected from
          public sources which may not be accurate or even false, and you therefore agree to hold the Site harmless from
          any legal or equitable disputes that may be derived or caused by any and all information available through our
          Site and from any damages that may be derived or caused by any and all information available through our Site.
        </li>
        <li>You understand and acknowledge that information obtained from public data sources may be manipulated and
          therefore agree to hold the Site harmless from any legal or equitable disputes that may be derived or caused
          by
          any and all information available through our Site and from any damages that may be derived or caused by any
          and
          all information available through our Site.
        </li>
        <li>You agree that you are a person who is legally authorized person or a duly authorized person of or a
          representative for a company with the appropriate consent and legal authority to utilize the services of the
          Site, which may include web screening.
        </li>
        <li>You agree that you are of the legal age to use our services and agree that you have consented to use the
          site
          to use our services, receive our services, and submit information to our services.
        </li>
        <li>You agree to use our Site and declare and attest your personable identifiable information.</li>
        <li>You agree and acknowledge that any public data information that you may be accessing through our Site shall
          not be altered in any way and that any third party data source shall not be held liable for any consequences
          stemming from your reuse of the data.
        </li>
        <li>You understand and acknowledge that public data sources may be of a sensitive nature such as employment
          records, civil rights, counterterrorism, popular culture, unusual phenomenon and violent crimes and that such
          data shall not be used to commit any illegal or immoral acts by you. In the event that any such illegal or
          immoral acts are committed by You with the use of this data, you agree that the Site or the third-party data
          source may not be held responsible.
        </li>
        <li>Trustd.ai may remind a user via, email, SMS or a phone call with news "flashes" or updates.</li>
        <li>Trustd.ai is not responsible for any civil, negligent, criminal or reckless conduct, damages, or behavior of
          its users or for any behavior and damages to its user’s that may be caused by others. Trustd.ai is not
          responsible for you not being of the legal age in your country.
        </li>
        <li>Trustd.ai is not responsible for a user’s past, current, or future conduct as it relates to their use of the
          Service. Trustd.ai may record the time and location of where you are. However, such information will only be
          disclosed if discovered or subpoenaed by court order. Trustd.ai may record and keep your personal identifiable
          information associated with your account. Such information will be retained for a limited period of time and
          in
          accordance with the Privacy Policy.
        </li>
        <li>Trustd.ai may require you to log in by using an existing social media account or by creating a standalone
          account. The Service will not provide your account information to any other users and will not make available
          nor be responsible for any links between your use of the service and your social media accounts.
        </li>
        <li>Trustd.ai is not responsible for any additional SMS, email, phone, or data charges that may result from a
          user
          not shutting off the service’s reminders. Such additional charges are borne by the user.
        </li>
        <li>Trustd.ai shall not be responsible for any legal disputes, potential or actual, in delivering our service to
          you which may include our scores and background checks.
        </li>
        <li>You understand that the public sources we use include but are not limited to, FBI’s most wanted, Sex
          Offender
          Lists, Department of Correction Databases, Interpol Red List, Suspicious IPs. You understand that our service
          accesses FBI lists and Interpol Databases to our local databases where it is screened locally. You also
          understand that our service checks Sex Offender Lists and Department of Correction lists and accesses
          information directly from their sources without any additional screening.
        </li>
        <li>You understand that the information we deliver to you as a service has 2 components, a Trust Confidence
          Score
          and a Screening from the public sources we access.
        </li>
        <li>You understand that information we collect from the public source may not be accurate or even may be false.
          Thus, you agree to not hold our Site or Service liable for any information that may be inaccurate or false.
        </li>
        <li>You understand that public source data which may be provided to you may be manipulated thus you agree not to
          hold our Site or Service liable for any potential or actual damage that may occur from this manipulated data.
        </li>
        <li>By using our service, you consent to our Services, these terms and conditions and our privacy policy.</li>
        <li>By using our service, you consent and agree that you are directly or indirectly authorized to use our Site
          or
          Service.
        </li>
        <li>If you are using this service as part of a company or organization then you are deemed to have provided
          consent prior to your use of the service as part of your employment or associate with the company.
        </li>
        <li>You agree that the Site or Service is not responsible for any person who may claim that they are using the
          service without your consent.
        </li>
        <li>You understand that the Site or Service does not verify all information that is collected or entered by You.
          Thus, by agreeing to use the Service you agreeing to providing accurate and truthful data. Should the data you
          enter not be truthful, or is false or inaccurate, you agree that the Site or Service shall not be liable or
          accountable for any actual or potential damage that may occur as a result of the data you entered. Data shall
          include your personal identifiable data as well as your credit score.
        </li>
        <li>You agree that person who is entering any data into the Site or Service is authorized to do so and has the
          consent to do so.
        </li>
        <li>You agree that the Site or Service shall not be responsible for any unauthorized data entry.</li>
        <li>You agree that and the person entering the data shall be liable for all potential and actual damage if such
          person is not authorized to enter data.
        </li>
        <li>You understand that our Data is comprised of a Trust Confidence Score and is delivered as a weighted score
          or
          ranged score. You understand that you will not receive an actual score.
        </li>
        <li>For BACKGROUND CHECKS: If a person’s name is matched in a database during a background screening, our
          Service
          delivers this information as it corresponds to the database where the match occurred. The match is flagged,
          and
          the contents of the record are delivered to you. If there is no match, then the match is marked as clear as it
          corresponds to the database and such information is reported to you.
        </li>
        <li>You understand that the use of the term Trust Confidence Score does not imply that a person is trustworthy.
        </li>
        <li>You agree that our Trust Confidence Score shall not be used for any other purpose, directly or indirectly,
          other than what is intended for as per the terms and conditions of the Site and Service.
        </li>
        <li>You understand that any question that we may ask you must be answered. If a question is not answered then
          the
          Site or Service assigns a default score, which is a score that is calculated as the average values of all
          assumed population/data.
        </li>
        <li>You understand that the Site or Service does not discriminate against gender, race, nationality, color, age,
          religion, education, profession, community, or any other factor or topic that could be demed discriminatory.
          Our
          Site and Service are independent of any discriminatory factor.
        </li>
      </ul>

      <p><strong>The Internet connection is under your responsibility and at your expense.</strong> Transmitting and
        receiving real-time updates to and from the Service, require an online (Wi-Fi,3G, 4G, LTE or 5G) connection
        between your cellular device and the Internet. The expenses of such connection are as prescribed by the
        agreement
        between you and your communication service provider (such as your cellular company), and according to its
        applicable terms of payment.
      </p>

      <h3>Intellectual Property </h3>
      <p>The Service, its database, Trustd.ai trademarks, copyrights, and any other intellectual property owned by
        Trustd.ai is not free and not available for use without express written consent of Trustd.ai. Trustd.ai also
        does
        not take responsibility for any user of this site who may be using another person’s image or Intellectual
        Property
        unlawfully or intentionally. Trustd.ai will ensure that any such activity will be immediately removed from the
        Site and Service and use reasonable best efforts to ensure that all users are the legal and rightful owners of
        their Intellectual Property.</p>

      <h3>Your Age</h3>
      <p>The Service is intended for use by users at or above the age of 18 years old, unless authorized or with the
        knowledge of a parent or guardian.</p>

      <h3>Privacy</h3>
      <p>Your privacy is important to us. While using the Service, personal information may be provided by you or
        collected by Trustd.ai as detailed in our Privacy Policy described in "Trustd.ai Privacy Policy" section below.
        The Privacy Policy explains our practices pertaining to the use of your personal information and we ask that you
        read such Privacy Policy carefully. By accepting these Terms, you hereby acknowledge and agree to the
        collection,
        storage and use of your personal information by Trustd.ai, subject to this section, the Privacy Policy and any
        applicable laws and regulation.</p>

      <h3>Feedback</h3>
      <p>Your feedback on the Service we provide is important to us. By accepting these Terms, you agree that any
        feedback
        you may provide is constructive and may be used by Trustd.ai for whatever purpose Trustd.ai deems necessary. You
        further agree that you claim no ownership to any feedback you may submit and to any implementation by Trustd.ai
        of
        submitted feedback.</p>

      <h3>Using The Service</h3>
      <p>You may use the Service solely for the purposes described herein and not for private purposes. You must not use
        the Service commercially. For example, you may not offer to third parties a service of your own that uses the
        Service; you may not resell the Service, offer it for rent or lease, offer it to the public via communication or
        integrate it within a service of your own, without the prior written consent of Trustd.ai.</p>

      <p>You must not copy, print, save or otherwise use the data from the Site or the Service's database. This clause
        does not limit the use of the database as intended by the Service and for the purposes of private and personal
        use
        of the Service.</p>

      <p>When using the Service or the Site you may not engage in scraping, data mining, harvesting, screen scraping,
        data
        aggregating, and indexing. You agree that you will not use any robot, spider, scraper or other automated means
        to
        access the Site or the Service’s database for any purpose without the express written permission of Trustd.ai.
        The
        Service may not be used in any way not expressly permitted by these Terms.</p>

      <p>You may not sell or offer to sell any Data that is freely or otherwise available for purchase on the Service.
        All
        Data belongs to Trustd.ai and may be used and/or sold by Trustd.ai for any purpose whatsoever. </p>

      <p>Except as explicitly provided herein, nothing in this Agreement shall be deemed to create a license in or under
        any such Intellectual Property Rights, and you agree not to sell, license, rent, modify, distribute, copy,
        reproduce, transmit, publicly display, publicly perform, publish, adapt, edit or create derivative works from
        any
        Trustd.ai content. Use of Trustd.ai content for any purpose not expressly permitted by this Agreement is
        strictly
        prohibited.</p>

      <p>You may choose to, or we may invite you to submit comments or ideas about the Service, including without
        limitation about how to improve the Service or our products ("Ideas" of "Feedback"). By submitting any Idea, you
        agree that your disclosure is gratuitous, unsolicited and without restriction and will not place Trustd.ai under
        any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to
        you, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone. You further acknowledge
        that,
        by acceptance of your submission, Trustd.ai does not waive any rights to use similar or related ideas previously
        known to Trustd.ai, or developed by its employees, or obtained from sources other than you. </p>

      <h3>Use Restrictions</h3>
      <p>There are certain conducts which are strictly prohibited on the Service. Please read the following restrictions
        carefully. Your failure to comply with the provisions set forth below may result (at Trustd.ai sole discretion)
        in
        the termination of your access to the Service and may also expose you to civil and/or criminal liability.</p>

      <p><strong>You may not, whether by yourself or anyone on your behalf:</strong> (i) copy, modify, adapt, translate,
        reverse engineer, decompile, or disassemble any portion of the Content at the Service and/or Site, in any way or
        publicly display, perform, or distribute them; (ii) make any use of the Content on any other website or
        networked
        computer environment for any purpose, or replicate or copy the Content without Trustd.ai’s prior written
        consent;
        (iii) create a browser or border environment around the Content (no frames or inline linking); (iv) interfere
        with
        or violate any third party or other user's right to privacy or other rights, including copyrights and any other
        intellectual property rights of others, or harvest or collect personal information about visitors or users of
        the
        Service and/or Site without their express consent, including using any robot, spider, site search or retrieval
        application, or other manual or automatic device or process to retrieve, index, or data-mine; (v) defame, abuse,
        harass, stalk, threaten, or otherwise violate the legal rights of others, including others’ copyrights, and
        other
        intellectual property rights; (vi) transmit or otherwise make available in connection with the Service and/or
        Site
        any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer code, file, or program that
        may
        or is intended to damage or hijack the operation of any hardware, Software, or telecommunications equipment, or
        any other actually or potentially harmful, disruptive, or invasive code or component; (vii) interfere with or
        disrupt the operation of the Service and/or Site, or the servers or networks that host the Service and/or Site
        or
        make the Service and/or Site available, or disobey any requirements, procedures, policies, or regulations of
        such
        servers or networks; (viii) sell, license, or exploit for any commercial purposes any use of or access to the
        Content and/or the Service and/or Site; (ix) frame or mirror any part of the Service and/or Site without
        Trustd.ai
        prior express written authorization; (x) create a database by systematically downloading and storing all or any
        of
        the Content from the Service and/or Site; (xi) forward any data generated from the Service and/or Site without
        the
        prior written consent of Trustd.ai ; (xii) transfer or assign your accounts' password, even temporarily, to a
        third party; (xiii) use the Service and/or Site for any illegal, immoral or unauthorized purpose; (xiv) use the
        Site, the Service, the Content and/or for non-personal or commercial purposes without Trustd.ai express prior
        written consent; or (xv) infringe or violate any of the Terms.</p>

      <h3>Termination of use of the Service</h3>
      <p>You may terminate your use of the Service at any time and for whatever reason. Trustd.ai retains the right to
        block your access to the Service and discontinue your use of the Service, at any time and for any reason
        Trustd.ai
        deems appropriate, at its sole and absolute discretion. To the extent possible, we will advise you of your
        account
        termination.</p>

      <p>Trustd.ai reserves the right to change, suspend, remove, discontinue, or disable access to the Service
        (including, but not limited to, the Application) at any time without notice. In no event will Trustd.ai be
        liable
        for the removal of or disabling of access to any portion or feature of the Service (including, but not limited
        to,
        the Application).</p>

      <p>If you breach any of the terms or conditions of the Terms or Trustd.ai discontinues their Service, the Terms
        will
        automatically terminate. All of the sections of the Terms will survive any termination of the Terms except the
        License section and the Consent to Use of Data and Mobile Communications section. </p>

      <h3>Intellectual Property</h3>
      <p>All intellectual property rights in the Service and its database, including copyrights, trademarks, industrial
        designs, patents and trade secrets – are either exclusive property of Trustd.ai or exclusively licensed to
        Trustd.ai. The Service is protected, among others, by United States Copyright and Trademark Law or by provisions
        prescribed by any other law, in the United States and abroad.</p>

      <p>Copying, distributing, publicly displaying, offering to the public via communication, transferring to the
        public,
        modifying, adapting, processing, creating derivative works, selling or leasing, any part of the Service, in any
        manner or means without the prior written consent of Trustd.ai , is strictly forbidden. "Trustd.ai ", Trustd.ai
        logo, and other trade and/or service marks are property of Trustd.ai and may not be used in any of the
        aforementioned means.</p>

      <p>Trustd.ai may protect the Service by technological means intended to prevent unauthorized use of the Service.
        You
        undertake not to circumvent these means. Without derogating Trustd.ai rights under these Terms or under any
        applicable law, infringement of the rights in and to the Service will, in and on itself, result in the
        termination
        of all your rights under these Terms. In such an event, you must immediately cease any and all uses of the
        Service, and within your obligations to Trustd.ai, you undertake to do so.</p>

      <h3>Mobile System</h3>
      <p>We may make available the System to access the Service via a mobile device ("Mobile System"). To use the Mobile
        System, you must have a mobile device that is compatible with the Mobile Service. Trustd.ai does not warrant
        that
        the Mobile System will be compatible with your mobile device. Trustd.ai hereby grants you a non-exclusive,
        non-transferable, revocable license to use a compiled code copy of the Mobile System for one Trustd.ai account
        owned or leased solely by you, for your personal use. You may not: (i) modify, disassemble, decompile or reverse
        engineer the Mobile System, except to the extent that such restriction is expressly prohibited by law; (ii)
        rent,
        lease, loan, resell, sublicense, distribute or otherwise transfer the Mobile System to any third party or use
        the
        Mobile System to provide time sharing or similar services for any third party; (iii) make any copies of the
        Mobile
        System; (iv) remove, circumvent, disable, damage or otherwise interfere with security-related features of the
        Mobile System, features that prevent or restrict use or copying of any content accessible through the Mobile
        System, or features that enforce limitations on use of the Mobile System; or (v) delete the copyright and other
        proprietary rights notices on the Mobile System. You acknowledge that Trustd.ai may from time to time issue
        upgraded versions of the Mobile System and may automatically electronically upgrade the version of the Mobile
        System that you are using on your mobile device. You consent to such automatic upgrading on your mobile device
        and agree that the terms and conditions of this Agreement will apply to all such upgrades. Any third-party code
        that
        may be incorporated in the Mobile System is covered by the applicable open source licenses, free software
        licenses, third-party licenses, and/or End User Licenses, if any, authorizing use of such code. The foregoing
        license grant is not a sale of the Mobile System or any copy thereof, and Trustd.ai or its third-party partners
        or suppliers retain all right, title, and interest in the Mobile System (and any copy thereof). Any attempt by
        you
        to transfer any of the rights, duties or obligations hereunder, except as expressly provided for in this
        Agreement,
        is void. </p>

      <p>Trustd.ai reserves all rights not expressly granted under this Agreement. If the Mobile System is being
        acquired on behalf of the United States Government, then the following provision applies. Use, duplication, or
        disclosure of the Mobile System by the U.S. Government is subject to restrictions set forth in this Agreement
        and as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR
        12.212(a)
        (1995), FAR 52.227-19, or FAR 52.227-14 (ALT III), as applicable. The Mobile System originates in the United
        States and
        is subject to United States export laws and regulations. The Mobile System may not be exported or re-exported to
        certain countries or those persons or entities prohibited from receiving exports from the United States. In
        addition, the Mobile System may be subject to the import and export laws of other countries. You agree to comply
        with all United States and foreign laws related to use of the Mobile System and the Trustd.ai Service. (1)
        Mobile
        System from iTunes or Google Play. The following applies to any Mobile System you acquire from the iTunes Store
        or
        via Google Play Store: You acknowledge and agree that this Agreement is solely between you and Trustd.ai, not
        Apple/Android, and that Apple/Android has no responsibility for the software or content thereof. Your use of the
        software must comply with the App Store Terms of Service. You acknowledge that Apple/Android has no obligation
        whatsoever to furnish any maintenance and support services with respect to the software. In the event of any
        failure of the software to conform to any applicable warranty, you may notify Apple/Android, and Apple/Android
        will refund the purchase price for the Software to you; to the maximum extent permitted by applicable law,
        Apple/Android will have no other warranty obligation whatsoever with respect to the Software, and any other
        claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty
        will be solely governed by this Agreement and any law applicable to Trustd.ai as provider of the Service. You
        acknowledge that Apple/Android is not responsible for addressing any claims of you or any third party relating
        to
        the software or your possession and/or use of the software, including, but not limited to: (i) product liability
        claims; (ii) any claim that the software fails to conform to any applicable legal or regulatory requirement; and
        (iii) claims arising under consumer protection or similar legislation; and all such claims are governed solely
        by
        this Agreement and any law applicable to Trustd.ai as provider of the Service. You acknowledge that, in the
        event
        of any third party claim that the software or your possession and use of that software infringes that third
        party’s intellectual property rights, Trustd.ai , not Apple/Android, will be solely responsible for the
        investigation, defense, settlement and discharge of any such intellectual property infringement claim to the
        extent required by this Agreement. You and Trustd.ai acknowledge and agree that Apple/Android, and
        Apple/Android’s
        subsidiaries, are third party beneficiaries of this Agreement as relates to your license of the software, and
        that, upon your acceptance of the terms and conditions of this Agreement, Apple/Android will have the right (and
        will be deemed to have accepted the right) to enforce this Agreement as relates to your license of the software
        against you as a third party beneficiary thereof.
      </p>

      <p>If you use the Service through an Apple/Android device, then you agree and acknowledge that:</p>
      <ul>
        <li>Apple/Android, Inc. bears no duties or obligations to you under the Terms, including, but not limited to,
          any
          obligation to furnish you with Service maintenance and support;
        </li>
        <li>You will have no claims, and you waive any and all rights and causes of action against Apple/Android with
          respect to the Service or the Terms, including, but not limited to claims related to maintenance and support,
          intellectual property infringement, liability, consumer protection, or regulatory or legal conformance;
        </li>
        <li>Apple/Android and Apple/Android’s subsidiaries are third party beneficiaries of the Terms. Upon your
          acceptance of the Terms, Apple/Android will have the right (and will be deemed to have accepted the right) to
          enforce these Terms against you as a third-party beneficiary thereof.
        </li>
      </ul>

      <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING TRUSTD.AI AND ANY SERVICES PROVIDED BY US,
        YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE. IF
        YOU
        DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT USE TRUSTD.AI SERVICE OR ANY SERVICES PROVIDED BY
        US.</p>

      <p>We reserve the right to change or modify any of the terms and conditions contained in the Terms or any policy
        or
        guideline of the Service, at any time and in our sole discretion. Any changes or modifications will be effective
        immediately upon the posting of such revisions on our website located at trustd.ai (the "Site") or when such
        revisions are made available via the Service, and you waive any right you may have to receive specific notice of
        such changes or modifications. Your continued use of the Service (or any portion thereof) following the posting
        of
        changes or modifications will confirm your acceptance of such changes or modifications. Therefore, you should
        frequently review the Terms and applicable policies from time-to-time to understand the terms and conditions
        that
        apply to your use of the Service. If you do not agree to the amended Terms, you must stop using the Service.</p>

      <h3>Device Usage Terms and Conditions</h3>
      <p>You acknowledge and agree that your use of the Service must also be in accordance with the usage rules
        established by your mobile device platform or service provider.</p>

      <h3>Registration Data</h3>
      <p>You agree to: (i) provide accurate, current and complete information about you as may be prompted by any
        signup,
        login and/or registration forms made available via the Service ("Registration Data"); (ii) maintain and promptly
        update the Registration Data, and any other information you provide to Trustd.ai, in order to keep it accurate,
        current and complete; and (iii) accept all risk of unauthorized access to the Registration Data and any other
        information you provide to Trustd.ai.</p>

      <p>You may not use the Service if you are less than 13 years of age, whether authorized or with the knowledge of a
        parent or guardian. By using the Trustd.ai software you verify that you are entering into this agreement with a
        clear mind.</p>

      <p>Trustd.ai is under no obligation to retain a record of your account or any data or information that you may
        have
        stored by means of the account or your use of the Services. You are only authorized to create and use one
        account
        for the Service and are prohibited from utilizing alter-egos or other disguised identities when utilizing the
        Application or Services. You are under no obligation to use or continue to use the Application or Services and
        may
        cease use of the Application or Services without notice to Trustd.ai.</p>

      <h3>Grant And Restrictions</h3>
      <p>Subject to the terms, conditions and limitations set forth in the Terms, Trustd.ai grants you a non-exclusive,
        non-transferable and revocable license to use the software on any mobile device that you own or control. The
        terms
        of the license will also govern any upgrades provided by Trustd.ai that replace and/or supplement the original
        software, unless such upgrade is accompanied by a separate license, in which case the terms of that license will
        govern.</p>

      <p>You agree not to do, or authorize or permit any third-party to do, any of the following: (i) distribute or make
        the software available over a network where it could be used by multiple devices at the same time; (ii) rent,
        lease, lend, sell, redistribute or sublicense the software; (iii) copy, decompile, reverse engineer,
        disassemble,
        attempt to derive the source code of, modify, or create derivative works of the software, any updates, or any
        part
        thereof (except as and only to the extent any of the foregoing restrictions are prohibited by applicable law);
        or
        (iv) remove, alter or obscure any copyright, trademark or other proprietary rights notice on or in the software.
        If you violate any of the foregoing restrictions, your use of the software will immediately cease and you will
        have infringed the copyright and other rights of Trustd.ai, which may subject you to prosecution and damages.
        Trustd.ai reserves all rights not expressly granted to you in the Terms.</p>

      <h3>Consent To Use Of Data And Mobile Communications</h3>
      <p>You agree that Trustd.ai may collect and use technical data, personal information and related information in
        connection with your use of the software including, but not limited to, contact information and technical
        information about your device, service and application Software, and peripherals, that are gathered periodically
        to facilitate the features and functionality of the software and of Service updates, product support and other
        services. You also consent to our communicating with you about the software or in connection with the features,
        functions and activities contained in the software. You also agree that any information that the Site or Service
        obtains from your social media accounts be used for whatever purpose Trustd.ai deems necessary.</p>

      <h3>Compatibility With Mobile Devices </h3>
      <p>Trustd.ai does not warrant that the Service will be compatible or interoperable with your mobile device or any
        other hardware, Software or equipment installed on or used in connection with your mobile device.</p>

      <h3>Carrier Charges</h3>
      <p>You acknowledge and understand that the Service requires and utilizes phone service, data access and text
        messaging capabilities. Carrier rates for phone, data and text messaging may apply and you are responsible for
        any
        such charges.</p>

      <h3>Electronic Communications</h3>
      <p>When you use the Service or send e-mails to us, you are communicating with us electronically, and you consent
        to
        receive communications from us electronically. We will communicate with you by e-mail or providing notices via
        the
        Service. You agree that all agreements, notices, disclosures and other communications that we provide to you
        electronically satisfy any legal requirement that such communications be in writing. Any such emails may include
        marketing and promotional content.</p>

      <h3>Third-Party Interactions</h3>
      <p>Your use of the software and your contact, interaction, or dealings with any third parties arising out of your
        use of the Service is solely at your own risk. You acknowledge and agree that Trustd.ai is not responsible or
        liable in any manner for any loss, damage or harm of any sort incurred as the result of the Service. Trustd.ai
        is
        not responsible, and shall not be held liable for, the acts, errors, omissions, representations, warranties,
        breaches or negligence of any such suppliers for any personal injuries, death, property damage, loss, theft or
        other damages or expenses resulting therefrom.</p>

      <h3>Third-Party Content </h3>
      <p>Trustd.ai may provide third-party content via the Service and may provide links to Web pages and content of
        third
        parties (collectively, the "Third-Party Content") as a service to those interested in this information.
        Trustd.ai
        does not control, endorse or adopt any Third-Party Content and makes no representation or warranties of any kind
        regarding the Third-Party Content including, but not limited to, its accuracy or completeness. You acknowledge
        and
        agree that Trustd.ai is not responsible or liable in any manner for any Third-Party Content and undertakes no
        responsibility to update or review any Third-Party Content. Users use such Third-Party Content contained therein
        at their own risk.</p>

      <h3>Advertisements And Promotions; Third-Party Products And Services</h3>
      <p>Trustd.ai may run advertisements and promotions from third parties via the Service or may otherwise provide
        information about or links to third-party products or services via the Service. Your business dealings or
        correspondence with, or participation in promotions of, such third parties, and any terms, conditions,
        warranties
        or representations associated with such dealings or promotions, are solely between you and such third-party.
        Trustd.ai is not responsible or liable for any loss or damage of any sort incurred as the result of any such
        dealings or promotions or as the result of the presence of such non-Trustd.ai advertisers or third-party
        information accessible via the Service.</p>

      <h3>Unlawful Activity</h3>
      <p>We reserve the right to investigate complaints or reported violations of the Terms and to take any action we
        deem
        appropriate including, but not limited to, reporting any suspected unlawful activity to law enforcement
        officials,
        regulators, or other third-parties and disclosing any information necessary or appropriate to such persons or
        entities relating to your Registration Data, usage history, posted materials, IP addresses, traffic information,
        and use of Trustd.ai.</p>

      <h3>Information And Press Releases</h3>
      <p>The Site may contain information and press releases about us. We disclaim any duty or obligation to update this
        information. Information about companies other than ours contained in any press release or otherwise should not
        be
        relied upon as being provided or endorsed by us.</p>

      <p>PLEASE READ CAREFULLY. THIS SECTION LIMITS TRUSTD.AI ’S RESPONSIBILITY TO YOU FOR USE OF THE SERVICE. WE MAKE
        NO
        REPRESENTATION OR WARRANTY TO YOU REGARDING THE SERVICE.</p>

      <h3>DISCLAIMERS</h3>
      <p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SERVICE (INCLUDING, BUT NOT LIMITED TO, THE APPLICATION) IS
        AT YOUR SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, SAFETY, ACCURACY AND EFFORT
        IS
        WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE (INCLUDING, BUT NOT LIMITED TO, THE
        APPLICATION) IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TRUSTD.AI DISCLAIMS ANY AND ALL WARRANTIES AND
        REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO THE TERMS AND THE SERVICE (INCLUDING, BUT
        NOT LIMITED TO, THE APPLICATION) WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM OR USAGE IN
        THE
        TRADE, BY COURSE OF DEALING OR OTHERWISE, INCLUDING ANY AND ALL: (I) WARRANTIES OF MERCHANTABILITY; (II)
        WARRANTIES OF FITNESS OR SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT TRUSTD.AI KNOWS, HAS REASON TO KNOW, HAS
        BEEN
        ADVISED OR IS OTHERWISE AWARE OF ANY SUCH PURPOSE); AND (III) WARRANTIES OF NON-INFRINGEMENT OR CONDITION OF
        TITLE. TRUSTD.AI DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE APPLICATION WILL BE ACCURATE OR MEET YOUR
        REQUIREMENTS, THAT THE OPERATION OF THE APPLICATION WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE
        APPLICATION WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION, GUIDELINES OR ADVICE GIVEN BY TRUSTD.AI OR ITS
        AUTHORIZED REPRESENTATIVE WILL CREATE A WARRANTY.</p>

      <p>You understand that the Service may be subject to downtime or otherwise unavailable for temporary periods of
        time
        due to maintenance or other reasons as we deem necessary. To the maximum extent allowed by law, we do not
        warrant
        any connection to, transmission over, or results or use of, any network connection or facilities provided (or
        failed to be provided) through the Service. You are responsible for assessing your own computer and transmission
        network needs, and the results to be obtained therefrom. You agree that we are not responsible or liable for any
        possible inadvertent inaccuracies in the information used by you on the Service. If you choose to include its
        Content in the Service, You are fully responsible for the accuracy of such listings and accuracy of the
        Content. </p>

      <p>EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT, YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE RISK.
        THE SERVICE IS PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS, UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF
        EXCLUSION. WE, ITS SUBSIDIARIES AND AFFILIATES (collectively, "TRUSTD.AI ") DISCLAIM ALL IMPLIED WARRANTIES AND
        CONDITIONS, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND THOSE ARISING FROM A COURSE OF DEALING OR USAGE
        OF
        TRADE, REGARDING THE SERVICE, AND THE INTERNET. TRUSTD.AI ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY
        YOU, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA, ITEMS OR INFORMATION, ANY LOSS OF PROFITS OR SAVINGS, LOSS OF
        USE, OR ANY OTHER COMMERCIAL LOSS, EVEN IF WE WAS ADVISED OF SUCH DAMAGES, FROM DELAYS, NON-DELIVERIES, ERRORS,
        SERVICE DOWN TIME, SERVICE UNAVAILABILITY, OR SERVICE INTERRUPTIONS CAUSED BY THE WE PARTIES OR BY YOUR OR ANY
        OTHER USER'S OWN ERRORS AND/OR OMISSIONS.</p>
      <p>YOU ACKNOWLEDGE AND AGREE THAT TRUSTD.AI SHALL NOT ASSUME OR HAVE ANY LIABILITY FOR ANY ACTION BY THE OTHER
        USERS
        WITH RESPECT TO CONDUCT, COMMUNICATION OR CONTENT ON THE SERVICE. NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR
        ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH THE SERVICE
        OR
        THESE TERMS. EXCEPT FOR YOUR INDEMNIFICATION OBLIGATIONS AS OUTLINED BELOW, EACH PARTY’S LIABILITY TO THE OTHER
        PARTY AND YOUR EXCLUSIVE REMEDY FOR YOUR BREACH OF ANY IMPLIED OR EXPRESS WARRANTY, OR FOR BREACH OF THIS
        AGREEMENT IS LIMITED SOLELY TO THE TOTAL AMOUNT OF FEES PAID BY YOU TO WE FOR USE OF THE SERVICE. BECAUSE SOME
        STATES OR COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN DAMAGES, IN SUCH STATES OR
        COUNTRIES EACH PARTY’S AND ITS RESPECTIVE SUBSIDIARIES' AND AFFILIATES' LIABILITY IS LIMITED TO THE EXTENT
        PERMITTED BY LAW.</p>

      <p>You agree to defend, indemnify and hold harmless Trustd.ai from all third-party liabilities, claims and
        expenses,
        including attorneys' fees ("Claims") arising from any breach of these Terms by You. </p>

      <p>You shall not be required to indemnify and hold Trustd.ai harmless for such Claims arising out of Trustd.ai’s
        willful misconduct, gross negligence or breach of these Terms. We agree to defend, indemnify and hold harmless
        you
        from any Claims that the Service infringes any patent, copyright, trade secret, or other proprietary right of a
        third party. A party being indemnified by the other party reserve the right, at its own expense, to assume the
        exclusive defense and control of any matter otherwise subject to indemnification by a party hereunder, and in
        such
        event, the party providing the indemnification shall have no further obligation to provide indemnification for
        such matter.</p>

      <p><strong>Legal Disputes.</strong> You agree that any claim or dispute at law or equity that has arisen or may
        arise between you and Trustd.ai will be resolved in accordance with the provisions set forth in this Legal
        Disputes Section. Please read this Section carefully. It affects your rights and will have a substantial impact
        on
        how claims between Trustd.ai and You are resolved.
      </p>

      <p>(a). Applicable Law.</p>
      <p>You agree that the laws of the State of New Jersey, without regard to principles of conflict of laws, will
        govern
        these Terms and any claim or dispute that has arisen or may arise between You and Trustd.ai , except as
        otherwise
        stated in this Agreement.</p>

      <p>(b). Agreement to Arbitrate.</p>
      <p>You agree that any and all disputes or claims that have arisen or may arise between Trustd.ai and You shall be
        resolved exclusively through final and binding arbitration, rather than in court, except that you may assert
        claims in small claims court, if the claim qualifies. The Federal Arbitration Act governs the interpretation and
        enforcement of this Agreement to Arbitrate.</p>

      <p>(c). Arbitration Procedures.</p>
      <p>Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge
        or
        jury, and court review of an arbitration award is very limited. However, an arbitrator can award the same
        damages
        and relief on an individual basis that a court can award to an individual. An arbitrator also must follow the
        terms of this Agreement as a court would.</p>
      <p>The arbitration will be conducted by the American Arbitration Association ("AAA") under its rules and
        procedures,
        including the AAA's Supplementary Procedures for Consumer-Related Disputes (as applicable), as modified by this
        Agreement to Arbitrate. The AAA's rules are available at www.adr.org. A form for initiating arbitration
        proceedings is available on the AAA's website at http://www.adr.org. </p>
      <p>The arbitration shall be held in the county in which Trustd.ai is incorporated. If the value of the relief
        sought
        is $10,000 or less, Trustd.ai and You may elect to have the arbitration conducted by telephone or based solely
        on
        written submissions, which election shall be binding on Trustd.ai and You subject to the arbitrator's discretion
        to require an in-person hearing, if the circumstances warrant. Attendance at an in-person hearing may be made by
        telephone by Trustd.ai and You unless the arbitrator requires otherwise. </p>
      <p>The arbitrator will decide the substance of all claims in accordance with the laws of the State of New Jersey,
        including recognized principles of equity, and will honor all claims of privilege recognized by law. The
        arbitrator shall be bound by rulings in prior arbitrations involving you to the extent required by applicable
        law.
        The arbitrator's award shall be final and binding and judgment on the award rendered by the arbitrator may be
        entered in any court having jurisdiction thereof.</p>

      <p>(d). Costs of Arbitration.</p>
      <p>Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules, unless otherwise
        stated in this Agreement to Arbitrate. In the event the arbitrator determines that the claim(s) you assert in
        the
        arbitration to be frivolous, you agree to reimburse Trustd.ai for all fees associated with the arbitration.</p>

      <p>(e). Judicial Forum for Legal Disputes.</p>
      <p>Unless You and We agree otherwise, in the event that the Agreement to Arbitrate above is found not to apply to
        You or to a particular claim or dispute, as a result of a court order, You agree that any claim or dispute that
        has arisen or may arise between You and We must be resolved exclusively by a state or federal court located in
        New
        Jersey. Trustd.ai and You agree to submit to the non-exclusive jurisdiction of the courts located within Morris
        County, New Jersey for the purpose of litigating all such claims or disputes.</p>
      <p>Nothing herein shall be construed as conveying title to the Service to You. The Service is and shall at all
        times
        remain the personal property of Trustd.ai. You agree that it will never represent that the title to the Service
        is
        in anyone other than Trustd.ai and shall never cooperate, whether actively or passively, with anyone claiming
        any
        right, title or interest in the Service, other than Trustd.ai.</p>
      <p>If any provision of this Agreement is unenforceable, such unenforceability shall not make any other provision
        hereof unenforceable. Any reference to any party includes its agents and employees. If any provisions of this
        Agreement conflict with any statute or rule of law in any jurisdiction wherein it may be sought to be enforced,
        then said provisions shall be deemed null and void to such extent, but without invalidating the remaining
        provisions.</p>
      <p>The remedies of this Agreement provided in favor of either party shall not be deemed exclusive, but shall be
        cumulative, and shall be in addition to all other remedies in its favor existing at law or in equity. The
        failure
        or delay of either party in exercising any right granted it hereunder upon any occurrence of any of the
        contingencies set forth herein shall not constitute a waiver of any such right upon the continuation or
        recurrence
        of any such contingencies or similar contingencies and any single or partial exercise of any particular right by
        either party shall not exhaust the same or constitute a waiver of any other right provided herein.</p>
      <p>Any failure by either party to require strict performance by the other party of any term, covenants or
        agreements
        herein shall not be construed as a consent or waiver of any breach of the same or of any other term, covenant or
        agreement herein. </p>
      <p>This Agreement constitutes the complete agreement and understanding among the parties, and supersedes all prior
        or contemporaneous proposals, oral or written, understandings, covenants, agreements, arrangements and
        communications between them relating to the subject matter of this Agreement. </p>
      <p>You acknowledge that you have had full opportunity to review this Agreement in detail and to seek independent
        legal representation and advice pertaining to this Agreement and either has done or has in its own independent
        business judgment chosen not to do so.</p>
      <p>This Agreement shall not be construed against Trustd.ai as the drafter of this Agreement.</p>
      <p>All notices given by you or required under this Agreement shall be in writing and addressed to Trustd.ai. </p>


      <h2>Trustd.ai Privacy Policy</h2>
      <p>This is the first version of Trustd.ai Privacy Policy and is effective as of December 1, 2020. Trustd.ai
        ("Trustd.ai," "we," or "us") is committed to protecting your privacy. This Privacy Policy explains our practices
        regarding the collection, use, disclosure, and protection of information that is collected through our web based
        application and/or our mobile application and/or our website (collectively, our "Service"), as well as your
        choices regarding the collection and use of information. </p>

      <p>This Privacy Policy describes how and when Trustd.ai collects, uses and shares your information when you use
        our
        Services. When using any of our Services, you consent to the collection, transfer, manipulation, storage,
        disclosure and other uses of your information as described in this Privacy Policy. Irrespective of which country
        you reside in or supply information from, you authorize Trustd.ai to use your information in the United States
        and
        any other country where Trustd.ai operates.</p>

      <p>If you have any questions or comments about this Privacy Policy, please contact us at support@trustd.ai.</p>

      <h3>Here’s Some Of The Information We Collect:</h3>
      <ul>
        <li>Information Collected Upon Registration. When you create or reconfigure an account with Trustd.ai, you
          provide
          some personal information, including, but not limited to social media information such as but not limited to
          your Twitter (username, password, email address) and Facebook account information (username, password).
          Further,
          residential and/or business information including address, state, zip code, etc. may be collected. Some of
          this
          information, for example, your name and username, may be listed publicly on our Services, including on your
          profile page and in search results. Some Services, such as search, public user profiles and viewing lists, may
          not require registration. You are also agreeing that you are of the legal age in your country to provide such
          data and be of the legal age to work and be employed as a model or artist.
        </li>
        <li>Account Information. This includes at least your username, and e-mail address;</li>
        <li>Network Performance & Usage Information. This tells us how you use our network, our products and our
          services,
          and how well our equipment and network is performing;
        </li>
        <li>Web Browsing & Wireless Application Information. This tells us about the websites you visit and the mobile
          applications you use whilst logged in to our network;
        </li>
        <li>Location Information. This tells us where your wireless device is located, as well as your zip code and
          street
          address;
        </li>
        <li>Geolocation. We may collect your location through GPS, Wi-Fi, or wireless network triangulation in order to
          obtain your location for the purposes of providing our Service. We may use geolocation data to build a history
          of occurrences of observed events that are the intent of Trustd.ai, in order to bring the most relevant
          information to you.
        </li>
        <li>Cookies. Like many websites, we use cookies and similar technologies to collect additional website usage
          data
          and to improve our Services, but we do not require cookies for many parts of our Services such as searching
          and
          looking at public user profiles or lists. A cookie is a small data file that is transferred to your computer's
          hard disk. Trustd.ai may use both session cookies and persistent cookies to better understand how you interact
          with our Services, to monitor aggregate usage by our users and web traffic routing on our Services, and to
          customize and improve our Services. Most Internet browsers automatically accept cookies. You can instruct your
          browser, by changing its settings, to stop accepting cookies or to prompt you before accepting a cookie from
          the
          websites you visit. However, some Services may not function properly if you disable cookies.
        </li>
        <li>Log Data. Our servers may automatically record information ("Log Data") created by your use of the Services.
          Log Data may include information such as your IP address, browser type, operating system, the referring web
          page, pages visited, location, your mobile carrier, device and application IDs, search terms, and cookie
          information. We may receive Log Data when you interact with our Services, for example, when you visit our
          website(s) or use our apps or otherwise sign into our Services, interact with our email notifications, use
          your
          Trustd.ai account to authenticate to a third-party website or application, or visit a third-party website that
          includes a Trustd.ai button or widget. Trustd.ai uses Log Data to provide our Services and to measure,
          customize, and improve them. If not already done earlier, we will either delete Log Data or remove any common
          account identifiers, such as your username, full IP address, or email address, after 18 months.
        </li>
        <li>Third-Parties. Trustd.ai may use a variety of third-party services to help provide our Services, such as
          hosting our blog(s), and to help us understand the use of our Services, such as Google Analytics. These
          third-party service providers may collect information sent by your browser as part of a web page request, such
          as cookies or your IP address. Third-party ad partners may share information with us, like a browser cookie ID
          or cryptographic hash of a common account identifier (such as an email address), to help us measure ad quality
          and tailor ads. For example, this allows us to display ads about things you may have already shown interest
          in.
          If you prefer, you can turn off tailored ads in your privacy settings so that your account is not matched to
          information shared by ad partners for tailoring ads.
        </li>
        <li><strong>European Union Members - International Transfer of Data:</strong> The Trustd.ai platform service is
          international in scope. By using the Services, you consent to your private information being sent and
          processed
          in other jurisdictions outside the European Union and European Economic Area, including the United States
          where
          there may be less stringent data protection laws.
        </li>
        <li><strong>For EU Users Only - Transferring Your Information Outside The European Economic Area:</strong> As
          part
          of the services offered to you through this website, the information which you provide to us may be
          transferred
          to countries outside the European Economic Area ("EEA"). By way of example, this may happen if any of our
          servers are from time to time located in a country outside of the EEA. These countries may not have similar
          data
          protection laws to the EEA. In such circumstances, we will enter into model contractual clauses as adopted by
          the European Commission, or rely on alternative legal bases such as the Privacy Shield, where applicable, or
          binding corporate rules where our affiliates, consultants or service providers have adopted such internal
          policies approved by European data protection authorities. If you use our services while you are outside the
          EEA, your information may be transferred outside the EEA in order to provide you with those services.
        </li>
        <li><strong>For EU Users Only -Legal Basis For The Processing Of Personal Information From EEA
          Residents:</strong>
          If you reside within the European Economic Area (EEA), our processing of your personal information will be
          legitimized as follows: Whenever we require your consent for the processing of your personal information such
          processing will be justified pursuant to Article 6(1) lit. (a) of the General Data Protection Regulation (EU)
          2016/679 ("GDPR"). If the processing of your personal information is necessary for the performance of a
          contract
          between you and us or for taking any pre-contractual steps upon your request, such processing will be based on
          GDPR Article 6(1) lit. (b). Where the processing is necessary for us to comply with a legal obligation, we
          will
          process your information on basis of GDPR Article 6(1) lit. (c), and where the processing is necessary for the
          purposes of our legitimate interests, such processing will be made in accordance with GDPR Article 6(1) lit.
          (f).
        </li>
        <li><strong>For EU Users Only -Cross-Border Transfer:</strong> We may share personal information with our
          affiliates' employees, consultants and third party service providers outside your country but only for
          purposes
          of performing the Services for which you provided your personal information, even to countries that might not
          offer a level of protection for your personal information that is equivalent to the one offered in your
          country
          of residence. We will obtain your express consent, however, before using your personal information for any
          purposes other than performing the Services for which you provided the personal information.
        </li>
        <li><strong>For EU Users Only -Choice/Opt-Out:</strong> You will be required to ‘opt-in’ to having your personal
          information used for any purpose not directly related to the Site upon registration to the Site. If you no
          longer wish to receive additional information or promotional materials from Trustd.ai, you may opt-out of
          receiving these communications by sending an email with the word ‘unsubscribe’ in the subject line to the
          following email address support@trustd.ai or using the opt-out mechanism described in the relevant email.
        </li>
      </ul>

      <h3>Here Are The Ways We Collect Your Information:</h3>
      <ul>
        <li>We get information from you when use our Service.</li>
        <li>We collect it from how you use our Service.</li>
      </ul>

      <h3>Here Are Some Of The Ways We Use Your Information:</h3>
      <ul>
        <li>Provide services and improve your customer experience;</li>
        <li>Address network integrity and security issues;</li>
        <li>Do research and analysis to maintain, protect, develop and improve our network;</li>
        <li>Let you know about service updates, offers and promotions;</li>
        <li>Deliver relevant advertising;</li>
        <li>Create external marketing & analytics reports;</li>
        <li>Assist in the prevention and investigation violations of our Terms of Use or Acceptable Use Policies.</li>
      </ul>

      <h3>Some Examples Of Who We Share Your Personal Information With:</h3>
      <ul>
        <li>With other companies that perform services on our behalf only as needed for them to perform those
          services. We require them to protect your information consistent with our Policy.
        </li>
        <li>With other companies and entities, to:</li>
        <li>Comply with court orders and other legal process;</li>
        <li>Assist with identity verification, and preventing fraud and identity theft;</li>
        <li>Enforce our agreements and property rights; and</li>
        <li>Obtain payment for products and services including the transfer or sale of delinquent accounts to third
          parties for collection.
        </li>
      </ul>

      <h3>Details on Personal and Anonymous & Aggregate Information</h3>
      <ul>
        <li>What is Personal Information? Information that identifies or reasonably can be used to identify you.</li>
        <li>What is Anonymous? This is information that does not identify you and cannot reasonably be used to
          identify you specifically.
        </li>
        <li>What is Aggregate? We take a large number of individual’s data and combine it into anonymous groups or
          categories.
        </li>
        <li>How we use this information? We use and share this information in many ways including research, analysis,
          marketing and relevant advertising.
        </li>
      </ul>

      <h3>Our Privacy Commitments</h3>
      <ul>
        <li>We do not sell your Personal Information to anyone for any purpose.</li>
        <li>We keep your Personal Information in our records while you are a user, or until it is no longer needed for
          business, tax, or legal purposes.
        </li>
        <li>We will keep your information safe using encryption or other appropriate security controls.</li>
      </ul>

      <h3>Modifying Your Personal Information</h3>
      <ul>
        <li>If you are a registered user of our Services, we may provide you with tools and account settings to access
          or modify the personal information you provided to us and associated with your account.
        </li>
        <li>You may also permanently delete your account by deleting the application from your mobile device or
          affirmatively deleting your user account via our website.
        </li>
      </ul>

      <h3>Our Online Privacy Policy For Children</h3>
      <ul>
        <li>We do not knowingly collect personally identifying information from anyone under the age of 13, unless
          authorized or with the knowledge of a parent or guardian.
        </li>
      </ul>

      <h3>Your Choices & Controls </h3>
      <ul>
        <li>You may have choices about which types of advertising you get from us.</li>
        <li>You may control whether your anonymous information is used in our External Marketing & Analytics Reports.
        </li>
        <li>You may choose whether to receive marketing calls, e-mails, or text messages from us.</li>
        <li>You may have a choice about how we use your Customer Proprietary Network Information.</li>
        <li>You agree that you are legally authorized and of an age to provide consent to use our services, access our
          services, receive information from our service, and submit information to our services.
        </li>
      </ul>
      <p>All references regarding opting-in or obtaining consent are only relevant to UK and EU consumers and clients
        under the General Data Protection Regulation ("GDPR").</p>

      <h3>Data Controllers and Contracting Parties</h3>
      <p>We clarify that Trustd.ai is acting as the data controller and contracting party for the data of some of our
        Members and Visitors. Trustd.ai will be the data controller and contracting party for those who live outside of
        the "Designated Countries" and will remain the data controller for those that live in the Designated Countries.
        We use the term "Designated Countries" to refer to countries in the European Union (EU), European Economic Area
        (EEA), and Switzerland.</p>

      <h3>Data Exchange with Insurance Companies</h3>
      <p>Trustd.ai may share your data, score, personal information, directly or indirectly through an analytics report,
        with insurance companies to improve the data model. Likewise, insurance companies may share your booking data,
        damage protection data, or any other personal data to help build more accurate data model to generate score or
        analytics report.</p>

      <h3>Cross-border Transfer</h3>
      <p>We may share personal information with our affiliates' employees, consultants and third party service providers
        outside your country but only for purposes of performing the Services for which you provided your personal
        information, even to countries that might not offer a level of protection for your personal information that is
        equivalent to the one offered in your country of residence. We will obtain your express consent, however, before
        using your personal information for any purposes other than performing the Services for which you provided the
        personal information.</p>

      <h3>Choice/Opt-out</h3>
      <p>You will be required to ‘opt-in’ to having your personal information used for any purpose not directly related
        to the Site upon registration to the Site. If you no longer wish to receive additional information or
        promotional materials from Trustd.ai, you may opt-out of receiving these communications by sending an email with
        the word ‘unsubscribe’ in the subject line to the following email address support@trustd.ai or using the opt-out
        mechanism described in the relevant email.</p>

      <h3>Notification of Changes</h3>
      <p>This policy may change from time to time and we will post the amended terms on the Trustd.ai Site and notify
        you by email of major changes. Amended terms will take effect immediately for new users, and 30 days after they
        are posted for existing users. You should review the Privacy Policy periodically to remain informed of any
        changes. You agree to accept posting of a revised Privacy Policy as actual notice to you. Your continued use of
        the Site after any changes to this Policy have been posted (or, if applicable, notified) signifies your
        acceptance of those changes. If you do not agree to the revised Privacy Statement, please refrain from using our
        products or services and contact us by sending an email to support@trustd.ai </p>

      <p>You can also request that we close your Trustd.ai account, or request that we no longer use your information to
        provide you Services, by emailing Customer Service at support@trustd.ai . After we close your account, we may
        retain some information if we believe it may be necessary to prevent fraud, assist with investigations, resolve
        disputes, analyze or troubleshoot programs, if required by law, enforce our Terms of Use and take actions
        otherwise permitted by law.</p>

      <h3>Questions and Feedback</h3>
      <p>Trustd.ai is strongly committed to our relationship with you and want to be sure you understand the steps we
        have taken to protect your personal information. If you have questions or feedback regarding our privacy policy,
        you may direct them to support@trustd.ai. We will make every effort to answer your questions. If you are still
        not satisfied with our response, you have the right to make a formal complaint to the relevant data protection
        authority.</p>

      <p>Trustd.ai encourages you to review your information regularly to ensure that it is correct and complete. As a
        registered user, you can review and change your personal information by contacting us at the e-mail address,
        postal address or telephone number listed on the Trustd.ai Site. You also have the right to access,
        rectification or erasure of the personal information held on our systems, the right to object or restrict the
        processing of your personal information, and the right to data portability. You may lodge a complaint with the
        competent data protection authority regarding the processing of your personal information. To protect the
        privacy and the security of your personal information, we may request information from you to enable us to
        confirm your identity and right to access such information, as well as to search for and provide you with the
        personal information we maintain. There are instances where applicable laws or regulatory requirements allow or
        require us to refuse to provide or delete some or all of the personal information that we maintain. You may
        contact us by sending an email to support@trustd.ai to exercise your rights. We will respond to your request in
        a reasonable timeframe, and in any event in less than 30 days. Trustd.ai agrees to hold your information for a
        period of at least 120 days unless a business reason requires, we hold it for more such as a subscription or you
        are a registered user.</p>

      <p>Customers who wish to request further information about our compliance with these requirements, or have
        questions or concerns about our privacy practices and policies may contact us: support@trustd.ai</p>
    </div>

    <div>
      <Button label="I've read, understood and accepted!" icon="pi pi-check" @click="closeTermsAndConditions"
              autofocus/>
    </div>

  </Dialog>

</template>

<script>
export default {
  name: "TermsAndConditions",
  data() {
    return {
      displayTermsAndConditions: false,
      displayServiceAgreement: false,
    }
  },
  methods: {
    openTermsAndConditions() {
      this.displayTermsAndConditions = true;
    },

    closeTermsAndConditions() {
      this.displayTermsAndConditions = false;
    },

    openServiceAgreement() {
      this.displayServiceAgreement = true;
    },

    closeServiceAgreemet() {
      this.displayServiceAgreement = false;
    },
  }
}
</script>

<style scoped>

</style>