'use strict';

import { convertToMMYYDDDD } from "@/utils/DateUtils";

export default class BookingInfo {
    
    constructor() {
        this.BOOKING_ID = null;
        this.LISTING_NAME = null;
        this.LISTING_CHANNEL = null;
        this.CHECK_IN_DATE = null;
        this.CHECK_OUT_DATE = null;
    }

    static create(rawData) {
        const bookingInfo = new BookingInfo();

        bookingInfo.BOOKING_ID = rawData.bookingId;
        bookingInfo.LISTING_NAME = rawData.listingName;
        bookingInfo.LISTING_CHANNEL = rawData.listingChannel;
        bookingInfo.CHECK_IN_DATE = convertToMMYYDDDD(rawData.checkInDate);
        bookingInfo.CHECK_OUT_DATE = convertToMMYYDDDD(rawData.checkOutDate);

        return bookingInfo;
    }

    cleanUpNullFields() {
        this.cleanUpField("BOOKING_ID");
        this.cleanUpField("LISTING_NAME");
        this.cleanUpField("LISTING_CHANNEL");
        this.cleanUpField("CHECK_IN_DATE");
        this.cleanUpField("CHECK_OUT_DATE");
    }

    cleanUpField(field) {
        if(this[field] == null) {
            delete this[field];
        }
    }
}