'use strict';

export default class TrustdLinkedinSearch {

  constructor() {
    this.status = null;
    this.matchCount = null;
  }

  static create(rawData) {
    let data = JSON.parse(JSON.stringify(rawData));
    console.log('data', data);

    const response = new TrustdLinkedinSearch();
    response.status = data.status;
    response.matchCount = data.match_count;

    return response;
  }
}
