const AuthService = require("@/service/AmplifyAuth");
const hostMenuItems = [
  {
    label: 'My Guests',
    icon: 'pi pi-fw pi-check-square',
    to: '/reviews'
  },
  {
    label: 'Run Trustd Score',
    icon: 'pi pi-fw pi-plus',
    to: '/checkscore'
  },
  {
    label: 'Account',
    icon: 'pi pi-fw pi-user',
    to: '/account'
  },
  {
    label: 'Log Out',
    icon: 'pi pi-fw pi-sign-out',
    command: async (event) => {
      console.log(event);
      await AuthService.signOut();
    }
  }
];

// const signedOutMenuItems = [];

const signedOutMenuItems = [
  {
    label: 'Login',
    icon: 'pi pi-fw pi-sign-in',
    to: '/login',
    command: () => {
      window.location.href = '/login';
    }
  },
  {
    label: 'Quick Access',
    icon: 'pi pi-fw pi-folder-open',
    items: [
      {
        label: 'Score Result',
        icon: 'pi pi-fw pi-plus',
        to: '/quickscoreresults',
        command: () => {
          window.location.href = '/quickscoreresults';
        }
      },
    ]
  },
  {
    label: 'Sign Up',
    icon: 'pi pi-unlock',
    to: '/signup',
    command: () => {
      window.location.href = '/signup';
    }
  }
];


// const localHostMenuItems = [
//   {
//     label: 'Localhost Menu',
//     icon: 'pi pi-fw pi-file',
//     items: [
//       {
//         label: 'Chart Sample',
//         icon: 'pi pi-fw pi-chart-bar',
//         to: '/chartsample'
//       },
//       {
//         separator: true
//       },
//       {
//         label: 'Components',
//         icon: 'pi pi-fw pi-chart-bar',
//         to: '/componenttest'
//       },
//       {
//         label: 'EnvVariables',
//         icon: 'pi pi-fw pi-chart-bar',
//         to: '/envvariables'
//       },
//     ]
//   }
// ];


module.exports = {
  hostMenuItems,
  signedOutMenuItems,
  // localHostMenuItems
}


/*
const signedOutMenuItems = [
  {
    label: 'Portal',
    icon: 'pi pi-fw pi-file',
    items: [
      {
        label: 'Authentication',
        icon: 'pi pi-fw pi-plus',
        to: '/authentication'
      },
      {
        label: 'DataStore',
        icon: 'pi pi-fw pi-plus',
        to: '/datastore'
      },
      {
        label: 'RestApi',
        icon: 'pi pi-fw pi-plus',
        to: '/restapi'
      },
      {
        label: 'Storage',
        icon: 'pi pi-fw pi-plus',
        to: '/storage'
      },
    ]
  },
  {
    label: 'File',
    icon: 'pi pi-fw pi-file',
    items: [
      {
        label: 'New',
        icon: 'pi pi-fw pi-plus',
        items: [
          {
            label: 'Bookmark',
            icon: 'pi pi-fw pi-bookmark'
          },
          {
            label: 'Video',
            icon: 'pi pi-fw pi-video'
          },

        ]
      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-trash'
      },
      {
        separator: true
      },
      {
        label: 'Export',
        icon: 'pi pi-fw pi-external-link'
      }
    ]
  },
  {
    label: 'Edit',
    icon: 'pi pi-fw pi-pencil',
    items: [
      {
        label: 'Left',
        icon: 'pi pi-fw pi-align-left'
      },
      {
        label: 'Right',
        icon: 'pi pi-fw pi-align-right'
      },
      {
        label: 'Center',
        icon: 'pi pi-fw pi-align-center'
      },
      {
        label: 'Justify',
        icon: 'pi pi-fw pi-align-justify'
      },

    ]
  },
  {
    label: 'Users',
    icon: 'pi pi-fw pi-user',
    items: [
      {
        label: 'New',
        icon: 'pi pi-fw pi-user-plus',

      },
      {
        label: 'Delete',
        icon: 'pi pi-fw pi-user-minus',

      },
      {
        label: 'Search',
        icon: 'pi pi-fw pi-users',
        items: [
          {
            label: 'Filter',
            icon: 'pi pi-fw pi-filter',
            items: [
              {
                label: 'Print',
                icon: 'pi pi-fw pi-print'
              }
            ]
          },
          {
            icon: 'pi pi-fw pi-bars',
            label: 'List'
          }
        ]
      }
    ]
  },
  {
    label: 'Events',
    icon: 'pi pi-fw pi-calendar',
    items: [
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Save',
            icon: 'pi pi-fw pi-calendar-plus'
          },
          {
            label: 'Delete',
            icon: 'pi pi-fw pi-calendar-minus'
          },

        ]
      },
      {
        label: 'Archieve',
        icon: 'pi pi-fw pi-calendar-times',
        items: [
          {
            label: 'Remove',
            icon: 'pi pi-fw pi-calendar-minus'
          }
        ]
      }
    ]
  }
];
 */
