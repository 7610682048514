'use strict';

const moment = require("moment");

const convertToMMYYDDDD = (value) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY');
  }

  return null;
}

const convertFromMMYYDDDD = (value) => {
  if (value) {
    return moment(value, 'MM/DD/YYYY').toDate();
  }

  return null;
}

/**
 * Checks a give date is valid, not null / empty
 *
 * @param date
 * @returns {boolean}
 */
const isValid = (date) => {
  return date && JSON.stringify(date).trim() !== '';
}


module.exports = {
  convertToMMYYDDDD,
  convertFromMMYYDDDD,
  isValid
}