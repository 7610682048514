'use strict';

import TrustdPhoneLookup from "@/model/TrustdPhoneLookup";
import TrustdLinkedinSearch from "@/model/TrustdLinkedinSearch";

export default class TrustdResponse {

  constructor() {
    this.trustdReference = null;
    this.guestId = null;
    this.score = null;
    this.score_numeric = null;
    this.scoreImage = null;
    this.criminalList = null;
    this.criminalListRecords = null;
    this.arrestList = null;
    this.arrestListRecords = null;
    this.wantedList = null;
    this.wantedListRecords = null;
    this.sexOffenderList = null;
    this.sexOffenderListRecords = null;
    this.watchList = null;
    this.watchListRecords = null;
    this.isSuspiciousIp = null;
    this.linkedinSearch = null;
    this.phoneLookup = null;
  }

  static create(rawData) {
    let data = JSON.parse(JSON.stringify(rawData));
    console.log('data', data);

    const response = new TrustdResponse();
    response.trustdReference = data.trustd_reference;
    response.guestId = data.guest_id;
    response.score = data.score;
    response.score_numeric = data.score_numeric;
    response.scoreImage = data.score_image;
    response.criminalList = data.criminal_list;
    response.criminalListRecords = data.criminal_lists_record;
    response.arrestList = data.arrest_list;
    response.arrestListRecords = data.arrest_lists_record;
    response.wantedList = data.wanted_list;
    response.wantedListRecords = data.wanted_lists_record;
    response.sexOffenderList = data.sex_offender_list;
    response.sexOffenderListRecords = data.sex_offender_lists_record;
    response.watchList = data.watch_list;
    response.watchListRecords = data.watch_lists_record;
    response.isSuspiciousIp = data.is_anonymous_ip;
    response.linkedinMatches = data.linkedin_matches;
    if (data.linkedin_search != null) {
      response.linkedinSearch = TrustdLinkedinSearch.create(data.linkedin_search);
    }

    if (data.phone_lookup != null) {
      response.phoneLookup = TrustdPhoneLookup.create(data.phone_lookup);
    }

    if(data.score === 'AVERAGE TRUST') {
      response.score = 'GOOD';
    }

    return response;
  }

}
