'use strict';

export default class TrustdUser {

  constructor() {
    this.username = null;
    this.contactName = null;
    this.company = null;
    this.email = null;
    this.website = null;
    this.phoneNumber = null;
    this.address = null;
    this.sendEmail = null;
    this.channel = null;
    this.screening = null;
    this.freeScreening = null;
    this.status = null;
  }

  static createFromUserForm(userForm) {
    console.log('userForm', userForm);

    const user = new TrustdUser();
    user.username = userForm.username;
    user.contactName = userForm.contactName;
    user.company = userForm.company;
    user.email = userForm.email;
    user.website = userForm.website;
    user.phoneNumber = userForm.phoneNumber;
    user.address = userForm.address;
    user.sendEmail = userForm.sendEmail;
    user.channel = userForm.channel;
    user.screening = userForm.screening;
    user.freeScreening = userForm.freeScreening;
    user.status = userForm.status;
    return user;
  }

}
