import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import PrimeVue from "primevue/config";
import Menubar from "primevue/menubar";
import BlockUI from "primevue/blockui";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Panel from "primevue/panel";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; //optional for column grouping
import Rating from "primevue/rating";
import Row from "primevue/row"; //optional for row
import Sidebar from "primevue/sidebar";
import Toast from "primevue/toast";
import Tree from "primevue/tree";
import ToastService from "primevue/toastservice";
import ProgressSpinner from "primevue/progressspinner";
import Tag from "primevue/tag";
import Tooltip from "primevue/tooltip";
import Avatar from "primevue/avatar";
import InputMask from "primevue/inputmask";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import "pretty-print-json";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import "@/assets/styles.scss";

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(PrimeVue);

// PrimeVue components
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component("Button", Button);
app.component("BlockUI", BlockUI);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Calendar", Calendar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Card", Card);
// eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
app.component("Dialog", Dialog);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Menubar", Menubar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Panel", Panel);
app.component("DataTable", DataTable);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Rating", Rating);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Row", Row);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Sidebar", Sidebar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Toast", Toast);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Tree", Tree);
// eslint-disable-next-line vue/multi-word-component-names
app.component("ProgressSpinner", ProgressSpinner);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Tag", Tag);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Avatar", Avatar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("InputMask", InputMask);

// PrimeVue directives
app.directive("tooltip", Tooltip);

app.use(ToastService);

app.component("VueJsonPretty", VueJsonPretty);

app.mount("#app");
