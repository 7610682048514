import { createStore } from 'vuex'

const store = createStore({
  state: {
    isUserSignedIn: false,
    user: null,
    shouldHideMenuNavigation: false,
    pastReviewsCurrentFilter: 'current',
  },
  mutations: {
    signInUser(state, user) {
      state.isUserSignedIn = true;
      state.user = user;
    },

    signOutUser(state) {
      state.isUserSignedIn = false;
      state.user = null;
    },

    shouldHideMenuNavigation(state, shouldHideMenuNavigation) {
      state.shouldHideMenuNavigation = shouldHideMenuNavigation;
    },

    updatePastReviewsFilter(state, filter) {
      state.pastReviewsCurrentFilter = filter;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default store;