'use strict';

import axios from 'axios';
import { Service } from 'axios-middleware';

let instance = null;

export default class AxiosService {
  constructor() {
    if (!instance) {
      instance = this;
      this._service = null;
    }

    return instance;
  }

  /**
   * See : https://emileber.github.io/axios-middleware/#/api/methods
   *
   * @param caller
   * @param onRequestCallbackFunction
   * @param onRequestErrorCallbackFunction
   * @param onSyncCallbackFunction
   * @param onResponseCallbackFunction
   * @param onResponseErrorCallbackFunction
   * @return {*}
   */
  register(caller, onRequestCallbackFunction, onRequestErrorCallbackFunction, onSyncCallbackFunction, onResponseCallbackFunction, onResponseErrorCallbackFunction, ) {
    if (!this._service) {
      this._service = new Service(axios);
    }

    this._service.register({
      onRequest(config) {
        console.log('onRequest');
        console.log(config);
        if (caller && onRequestCallbackFunction) {
          onRequestCallbackFunction(caller);
        }
        return config;
      },
      onRequestError(error) {
        console.log('onRequestError: ', error);
        if (caller && onRequestErrorCallbackFunction) {
          onRequestErrorCallbackFunction(caller, error);
        }

        throw error;
      },
      onSync(promise) {
        if (onSyncCallbackFunction != null) {
          console.log('onSync');
          if (caller) {
            onSyncCallbackFunction(caller);
          }
        }
        return promise;
      },
      onResponse(response) {
        console.log('onResponse');
        if (caller && onResponseCallbackFunction) {
          onResponseCallbackFunction(caller);
        }
        return response;
      },
      onResponseError(error) {
        console.log('onResponseError: ', error);
        if (caller && onResponseErrorCallbackFunction) {
          onResponseErrorCallbackFunction(caller);
        }

        throw error;
      },
    });
  }
}
