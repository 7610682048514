<template>
  <div class="loginBody">
    <div class="container-xxl">
      <div class="loginLeft">
        <div class="loginLeftLogo">
          <img
            alt="Trustd logo"
            src="../assets/trustd-logo.png"
            height="67"
            class="p-mr-1 logo-blend"
          />
        </div>
        <h2>Welcome Back To The Trustd.ai Host Portal</h2>
        <p>
          The First GIG Score for Short Term Rental Vertical, based on behavior
          of guests during their stay.
        </p>
        <div>
          <div class="cardContainer">
            <Card
              class="loginCard cardCenter cardW25"
              v-if="currentStage === STAGE_LOGIN"
            >
              <template #content>
                <div class="mb-5">
                  <label for="loginUsername" class="form-label"
                    >Email<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="loginUsername"
                    placeholder="Email Address"
                    v-model="loginForm.username"
                    v-on:keyup.enter="login"
                    required
                  />
                </div>
                <div class="mb-5">
                  <label for="loginPassword" class="form-label"
                    >Password<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="loginPassword"
                    placeholder="Password"
                    v-model="loginForm.password"
                    v-on:keyup.enter="login"
                    required
                  />
                </div>
                <div class="mb-5">
                  <button @click="login" class="btn btn-primary w-100">
                    Sign In
                  </button>
                </div>
                <div class="mb-5">
                  <a @click.stop="forgotPasswordOpen" href="#">
                    <span>Forgot password?</span>
                  </a>
                </div>
                <div>
                  <a @click.stop="signup" href="#">
                    <span class="secondary-a">Don’t have an account? </span>
                    <span>Click here to sign up!</span>
                  </a>
                </div>
              </template>
              <!--
                <template #footer>
                  <div class="text-center border-top">
                    <div class="w-100">Don't have an account?</div>
                    <Button label="Open an Account Now" class="p-button-secondary" @click="signup"/>
                  </div>
                </template>
          -->
            </Card>

            <Card
              class="loginCard cardCenter cardW25"
              v-if="currentStage === STAGE_EMAIL_VERIFICATION"
            >
              <template #content>
                <h4 class="mb-5">Email Verification</h4>
                <div class="mb-5">
                  <label for="loginUsernameFP" class="form-label"
                    >Verification Code<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="loginUsernameFP"
                    placeholder="Verification Code"
                    v-model="verificationCode"
                  />
                </div>
                <div class="mb-1 row">
                  <button class="btn btn-primary" @click="verify">
                    Submit
                  </button>
                  &nbsp;
                  <button
                    class="btn btn-warning"
                    @click="verificationCodeCancel"
                  >
                    Cancel
                  </button>
                </div>
              </template>
            </Card>

            <Card
              class="loginCard cardCenter cardW25"
              v-if="currentStage === STAGE_FORGOT_PASSWORD"
            >
              <template #content>
                <h4 class="mb-5">Forgot Password</h4>
                <div class="mb-5">
                  <label for="loginUsernameFP" class="form-label"
                    >Email<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="loginUsernameFP"
                    placeholder="User Email Address"
                    v-model="loginForm.username"
                  />
                </div>
                <div class="mb-1">
                  <button class="btn btn-primary" @click="forgotPasswordReset">
                    Reset Password
                  </button>
                  &nbsp;
                  <button class="btn btn-warning" @click="forgotPasswordCancel">
                    Cancel
                  </button>
                </div>
              </template>
            </Card>

            <Card
              class="loginCard cardCenter cardW50"
              v-if="currentStage === STAGE_FORGOT_PASSWORD_CODE"
            >
              <template #title> </template>
              <template #content>
                <h4 class="mb-5">Reset Password</h4>
                <p style="font-size: 20px">
                  Please Check your email for the Password Reset Code
                </p>
                <div class="mb-5">
                  <label for="loginCode" class="form-label"
                    >Password Reset Code<sup class="validatedField"
                      >*</sup
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="loginCode"
                    placeholder="code"
                    v-model="loginForm.code"
                  />
                </div>
                <div class="mb-5">
                  <label for="loginNewPassword1" class="form-label"
                    >New Password<sup class="validatedField">*</sup></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="loginNewPassword1"
                    placeholder="New Password"
                    v-model="loginForm.newPassword1"
                  />
                </div>
                <div class="mb-5">
                  <label for="loginNewPassword2" class="form-label"
                    >Re-Enter New Password<sup class="validatedField"
                      >*</sup
                    ></label
                  >
                  <input
                    type="password"
                    class="form-control"
                    id="loginNewPassword2"
                    placeholder="New Password"
                    v-model="loginForm.newPassword2"
                  />
                </div>
                <div class="mb-1">
                  <button class="btn btn-primary" @click="forgotPasswordCode">
                    Change Password
                  </button>
                  &nbsp;
                  <button class="btn btn-warning" @click="forgotPasswordCancel">
                    Cancel
                  </button>
                </div>
              </template>
            </Card>

            <div v-if="currentStage === STAGE_COMPLETE_NEW_PASSWORD_REQUIRED">
              <ChangePassword
                :passwordUser="passwordUser"
                :cancelCallBack="newPasswordRequiredCancelCallBack"
                :parentComponent="currentComponent"
              ></ChangePassword>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "@/service/AmplifyAuth";
import StringUtils from "@/utils/StringUtils";
import EmailUtils from "@/utils/EmailUtils";
import ChangePassword from "@/views/ChangePassword";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  name: "HostLogin",
  components: {
    ChangePassword,
  },
  data() {
    return {
      STAGE_LOGIN: "LOGIN",
      STAGE_FORGOT_PASSWORD: "FORGOT_PASSWORD",
      STAGE_FORGOT_PASSWORD_CODE: "FORGOT_PASSWORD_CODE",
      STAGE_COMPLETE_NEW_PASSWORD_REQUIRED: "COMPLETE_NEW_PASSWORD_REQUIRED",
      STAGE_EMAIL_VERIFICATION: "STAGE_EMAIL_VERIFICATION",
      verificationCode: null,
      loginForm: {
        username: "",
        password: "",
        code: "",
        newPassword1: "",
        newPassword2: "",
      },
      currentStage: "",
      passwordUser: null,
      currentComponent: null,
    };
  },
  mounted() {
    this.currentStage = this.STAGE_LOGIN;

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("is_api") && urlParams.get("is_api") === "ownerrez") {
      this.saveAfterOwnerrez();
    }
  },
  methods: {
    verificationCodeCancel() {
      this.currentStage = this.STAGE_LOGIN;
    },
    async saveAfterOwnerrez() {
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail:
          "Your Ownerrez account has been successfully linked to your account!",
        life: 10000,
      });
    },
    async login() {
      if (!this.validateLogin()) {
        return;
      }
      try {
        console.log("clicked login");
        const user = await AuthService.signIn(
          this.loginForm.username,
          this.loginForm.password
        );
        console.log("User logged in: ", user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$toast.add({
            severity: "warn",
            summary: "New Password Required",
            detail:
              "New Password Required for the first time signing in, please enter your new password!",
            life: 10000,
          });
          this.passwordUser = user;
          this.currentComponent = this;
          this.currentStage = this.STAGE_COMPLETE_NEW_PASSWORD_REQUIRED;
        } else {
          this.$store.commit("signInUser", user);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Login Successful",
            life: 5000,
          });
          await this.$router.push("/");
        }
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Login Unsuccessful: " + msg,
          life: 10000,
        });

        if (msg.includes("is not confirmed")) {
          this.currentStage = this.STAGE_EMAIL_VERIFICATION;

          AuthService.sendEmailVerificationCode(this.loginForm.username);
        }
      }
    },

    validateVerify() {
      let valid = true;

      if (StringUtils.isEmpty(this.verificationCode)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Verification Code is required",
          life: 5000,
        });
        valid = false;
      }
      return valid;
    },

    async verify() {
      console.log("Verify was initiated");
      if (!this.validateVerify()) {
        return;
      }
      try {
        await AuthService.confirmSignUp(
          this.loginForm.username,
          this.verificationCode
        );

        const user = await AuthService.signIn(
          this.loginForm.username,
          this.loginForm.password
        );

        console.log("User logged in: ", user);

        this.$store.commit("signInUser", user);

        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Code Verification Successful",
          life: 5000,
        });

        this.login();
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Sign Up Unsuccessful: " + msg,
          life: 10000,
        });
      }
    },

    validateLogin() {
      let valid = true;

      if (StringUtils.isEmpty(this.loginForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username is required",
          life: 5000,
        });
        valid = false;
      }

      if (!EmailUtils.isValid(this.loginForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username is not a valid email address",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.loginForm.password)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Password is required",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },

    forgotPasswordOpen() {
      this.currentStage = this.STAGE_FORGOT_PASSWORD;
    },

    forgotPasswordCancel() {
      this.currentStage = this.STAGE_LOGIN;
    },

    async forgotPasswordReset() {
      if (!this.validateForgotPasswordReset()) {
        return;
      }
      try {
        console.log("clicked Reset Password");
        await AuthService.forgotPassword(this.loginForm.username);
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Password Reset Code was sent to your email address!",
          life: 10000,
        });
        this.currentStage = this.STAGE_FORGOT_PASSWORD_CODE;
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Password Reset Code request Unsuccessful: " + msg,
          life: 5000,
        });
      }
    },

    validateForgotPasswordReset() {
      let valid = true;

      if (StringUtils.isEmpty(this.loginForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username is required",
          life: 10000,
        });
        valid = false;
      }

      if (!EmailUtils.isValid(this.loginForm.username)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Username is not a valid email address",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },

    async forgotPasswordCode() {
      if (!this.validateForgotPasswordCode()) {
        return;
      }
      try {
        console.log("clicked Reset Password");
        await AuthService.forgotPasswordSubmit(
          this.loginForm.username,
          this.loginForm.code,
          this.loginForm.newPassword1
        );
        this.$toast.add({
          severity: "success",
          summary: "Success",
          detail: "Password Changed Successfully!",
          life: 10000,
        });
        this.currentStage = this.STAGE_LOGIN;
      } catch (e) {
        const msg = ErrorUtils.parseError(e);
        console.error(msg, e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Password Changed Unsuccessful: " + msg,
          life: 10000,
        });
      }
    },

    validateForgotPasswordCode() {
      let valid = true;

      if (StringUtils.isEmpty(this.loginForm.code)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Password Reset Code is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.loginForm.newPassword1)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password is required",
          life: 5000,
        });
        valid = false;
      }

      if (StringUtils.isEmpty(this.loginForm.newPassword2)) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password ReEntry is required",
          life: 5000,
        });
        valid = false;
      }

      if (this.loginForm.newPassword1 !== this.loginForm.newPassword2) {
        this.$toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "New Password does not match the ReEntry",
          life: 5000,
        });
        valid = false;
      }

      return valid;
    },

    newPasswordRequiredCancelCallBack(self) {
      console.log("newPasswordRequiredCancelCallBack self:", self);
      self.currentStage = self.STAGE_LOGIN;
    },

    signup() {
      this.$router.push("signup");
    },
  },
};
</script>

<style scoped>
.loginCard a span:not(.secondary-a) {
  color: #00bbe1;
  text-decoration: underline;
  font-size: 18px;
}

.loginCard a .secondary-a {
  color: black;
  font-size: 18px;
}

.loginLeft h2 {
  font-size: 30px;
  font-weight: 700;
  color: #0086bf;
  margin-top: 0px;
  margin-bottom: 0px;
}

.loginLeft p {
  font-size: 20px;
  margin-top: 0px;
}

.loginCard {
  min-width: 550px;
}

.loginBody {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loginBody .container-xxl {
  display: flex;
  justify-content: space-between;
  padding: 50px;
}

.loginLeft {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.loginBody .loginCard {
  margin: 0;
}

.loginCard h4 {
  font-weight: 700;
}

@media screen and (max-width: 1350px) {
  .loginBody .container-xxl {
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .cardContainer {
    padding: 0 !important;
  }

  .loginCard {
    min-width: auto;
    width: 100%;
    box-shadow: none;
    padding: 0 !important;
  }

  .loginCard .p-card-body {
    padding: 0 !important;
    background-color: transparent !important;
  }

  .loginCard .p-card-content {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .loginBody .container-xxl {
    padding: 0;
  }

  .loginCard a span:not(.secondary-a) {
    color: #00bbe1;
    text-decoration: underline;
    font-size: 14px;
  }

  .loginCard a .secondary-a {
    color: black;
    font-size: 14px;
  }

  .loginLeft p {
    font-size: 14px;
  }
}
</style>
