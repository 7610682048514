<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const showBackButton = ref(true);

router.beforeEach((to, from, next) => {
  showBackButton.value = to.path !== '/';
  next()
});

</script>

<template>
  <div></div>
    <!-- <div class="layout-footer">
        <router-link v-if="showBackButton" to="/" class="ml-auto">
            <button class="btn btn-lg btn-primary">Back To Home Page</button>
        </router-link>
    </div> -->
</template>
<style lang="scss" scoped></style>
