<template>
  <Toast />
  <BlockUI :blocked="isUIBlocked" :fullScreen="true"></BlockUI>
  <MenuNavigation v-if="!shouldHideMenuNavigation"></MenuNavigation>
  <router-view />
</template>

<script>
import MenuNavigation from "./components/MenuNavigation";
import { Hub } from "aws-amplify";
import AuthService from "@/service/AmplifyAuth";
import AxiosService from "@/service/AxiosService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  name: "App",
  computed: {
    shouldHideMenuNavigation() {
      return this.$store.state.shouldHideMenuNavigation;
    },
  },
  components: {
    MenuNavigation,
  },
  data() {
    return {
      isUIBlocked: false,
    };
  },
  mounted() {
    this.setHubListeners();
    this.setAxiosListeners();

    if (!window.location.href.includes("/guestForm/")) {
      this.checkCurrentAuthenticatedUser();
    }
  },
  methods: {
    setHubListeners() {
      // set the hub / amplify listeners so we can update menu items
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            console.log("user signed in: ", data.payload.data);
            this.$store.commit("signInUser", data.payload.data);
            break;
          case "signOut":
            console.log("user signed out");
            this.$store.commit("signOutUser");
            this.$router.push("/login");
            break;
          case "signIn_failure":
            console.log("user sign in failed"); // todo add a toaster message here !!!
            break;
        }
      });
    },

    setAxiosListeners() {
      const axiosService = new AxiosService();
      axiosService.register(
        this,
        (caller) => {
          console.log(caller);
        },
        (caller, error) => {
          caller.isUIBlocked = false;

          if (error) {
            const msg = ErrorUtils.parseError(error);
            console.error(msg, error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "OnRequestError: " + msg,
              life: 10000,
            });
          }
        },
        null,
        (caller) => {
          caller.isUIBlocked = false;
        },
        (caller, error) => {
          caller.isUIBlocked = false;

          if (error) {
            const msg = ErrorUtils.parseError(error);
            console.error(msg, error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "OnResponseError: " + msg,
              life: 10000,
            });
          }
        }
      );
    },

    async checkCurrentAuthenticatedUser() {
      try {
        const user = await AuthService.currentAuthenticatedUser();

        console.log("user: ", user);

        if (user != null) {
          this.$store.commit("signInUser", user);
        }
      } catch (e) {
        console.error(
          `No Authenticated user found: ${ErrorUtils.parseError(e)}`,
          e
        );
      }
    },
  },
};

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth >= 1024 && window.innerWidth <= 1800) {
    // sadece desktop cihazlar için uygula
    document.body.style.zoom = "80%";
  }
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Cabin", sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 1em;
  // background-image: url('assets/background.png');
  background-color: #f8f9fa;
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  // background-image: url('assets/background.png');
  background-color: #ffffff;
  background-size: cover;
  position: relative;
}

.input-container input {
  margin-top: 6px;
  font-size: 18px !important;
}

.form-control::placeholder {
  color: gray !important;
}

.form-control {
  font-size: 18px !important;
}

.p-menubar {
  background-color: white !important;
}

.cardContainer {
  margin-top: 2em;
  text-align: center;
  padding: 0.5em;
}

.cardCenter {
  margin: auto;
  text-align: left;
}

.cardW25 {
  width: 25em;
}

.cardW50 {
  width: 50em;
}

.cardW75 {
  width: 75em;
}

.cardW100 {
  width: 100%;
}

.validatedField {
  color: red;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: gold !important;
}

span.vjs-value-string {
  color: maroon !important;
}

.p-button {
  color: #fff;
  background-color: rgba(0, 134, 191, 1);
  border: none !important;
}

.p-card {
  border-radius: 29px;
}

.p-card-body {
  background-color: #f5fbfb;
  border-radius: 29px;
}

.form-control {
  height: 55px;
  border-radius: 29px !important;
  border: none !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.form-label {
  font-size: 18px;
  font-weight: bold;
  padding-left: 10px;
}

.btn-primary {
  background-color: #0086bf !important;
}

.btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 29px !important;
  height: 55px;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
  border: none !important;
}

.p-card-content {
  padding: 32px;
}

.p-dropdown {
  border-radius: 29px !important;
  height: 55px;
}

.p-dropdown-label {
  padding-left: 32px;
}

.topInfoBar .p-card-body {
  background-color: #00bbe1;
}

.topInfoBar * {
  color: white;
}

.topInfoBarInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topInfoBarLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topInfoBarLeft h2 {
  font-size: 40px;
  font-weight: 700;
}

.topInfoBarLeft p {
  font-size: 18px;
}

.topInfoBarRight {
  background-color: #0086bf;
  padding: 30px;
  border-radius: 29px;
}

.topInfoBarRight h4 {
  font-size: 30px;
}

.topInfoBarRight p {
  font-size: 18px;
}

.topInfoBar .p-card-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dashboardCount {
  background: linear-gradient(180deg, #00bbe1 0%, #0086bf 100%) !important;
  border-radius: 29px !important;
}

.dashboardCount .value {
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.dashboardCount .title {
  font-size: 18px;
  color: white;
}

.layout-topbar .layout-topbar-button.displayable {
  width: auto !important;
}

.layout-topbar .layout-topbar-button.displayable span {
  display: block !important;
  margin-left: 10px;
}

.layout-topbar .layout-topbar-menu {
  gap: 20px;
}

.topUpCards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.layout-submenu a {
  box-shadow: none !important;
}

.layout-wrapper {
  background-color: white;
}

.signupBody {
  background-color: white;
}

.p-dialog {
  box-shadow: none !important;
}

.p-dialog .p-card-body {
  width: 967px;
}

@media screen and (max-width: 1100px) {
  .p-dialog {
    width: 95%;
  }
}

.topUpCard {
  width: 357px;
  height: 306px;
  border-radius: 29px;
  background: linear-gradient(180deg, #0086bf 0%, #00bbe1 100%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.topUpCard p {
  font-size: 25px;
  font-weight: 700;
  color: white;
}

.topUpCard h4 {
  font-size: 50px;
  font-weight: 700;
  color: white;
}

.topUpCard h5 {
  font-size: 40px;
  font-weight: 700;
  color: white;
}

.topUpCard h6 {
  font-size: 25px;
  font-weight: 700;
  color: white;
}

.topUpCardButton {
  width: 100%;
  height: 55px;
  background-color: white;
  border-radius: 29px;
  font-size: 18px;
  color: black;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkScore {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
}

.checkScore .p-panel {
  width: 951px;
}

.p-button {
  background-color: rgba(0, 134, 191, 1) !important;
  border: none;
}

.checkScore .p-panel-header {
  background-color: rgba(0, 187, 225, 1);
  height: 73px;
  color: white;
  border-radius: 29px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 700;
}

.checkScore .p-panel-content {
  border: none;
  margin-top: 20px;
  background-color: rgba(245, 251, 251, 1);
  border-radius: 29px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkScore .input-container label {
  font-size: 18px;
  font-weight: 700;
}

.checkScore .input-container input {
  font-size: 18px;
  padding-right: 30px !important;
  padding-left: 30px !important;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  border: none !important;
}

.checkScore .p-datepicker-trigger {
  width: 90px;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
}

.checkscore-action-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 50px;
}

.checkscore-action-text div {
  width: 300px;
}

.pastReviews .p-card-content {
  padding: 0px !important;
}

.pastReviews .p-card-body {
  background-color: white !important;
}

.pastReviews .p-component {
  box-shadow: none !important;
}

.pastReviews .filters {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.pastReviews .filters div {
  position: relative;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
}

.pastReviews .filters div span {
  width: 0;
  height: 8px;
  background-color: #0086bf;
  bottom: -10px;
  left: 0;
  position: absolute;
  border-radius: 29px;
  transition: 0.3s ease;
}

.pastReviews .filters div.active span,
.pastReviews .filters div:hover span {
  width: 100%;
}

.userAccount .p-card-content {
  padding: 0px;
}

.userAccount .p-card-body {
  background-color: white;
}

.linkCard .p-card-body {
  background-color: #f5fbfb !important;
}

.userAccount .p-card {
  box-shadow: none;
}

.userAccount .p-panel-header {
  background-color: rgba(0, 187, 225, 1);
  height: 73px;
  color: white;
  border-radius: 29px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 700;
}

.userAccount .p-panel-content {
  border: none;
  margin-top: 20px;
  background-color: rgba(245, 251, 251, 1);
  border-radius: 29px;
  padding: 40px;
  display: flex;
  gap: 20px;
}

.userAccount .accountBox .p-panel-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.userAccount .accountBoxRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.userAccount .accountBoxLeft {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 100px;
  width: 75%;
}

.userAccount .accountDetailBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.userAccount .accountDetailBox h6 {
  font-size: 18px;
  color: #4e4e4e;
}

.userAccount .accountDetailBox p {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.userAccount .p-card-content {
  padding: 0px;
}

.checkScoreResult .p-card-body {
  background-color: white;
}

.checkScoreResult .p-card {
  box-shadow: none;
}

.checkScoreResult .p-panel-header {
  background-color: rgba(0, 187, 225, 1);
  height: 73px;
  color: white;
  border-radius: 29px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 20px;
  font-weight: 700;
}

.checkScoreResult .p-panel-content {
  border: none;
  margin-top: 20px;
  background-color: rgba(245, 251, 251, 1);
  border-radius: 29px;
  padding: 40px;
  display: flex;
  gap: 20px;
}

.p-panel-content {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.checkScoreResult .accountBox .p-panel-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.checkScoreResult .accountBoxRight {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkScoreResult .accountBoxLeft {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 75%;
}

.checkScoreResult .accountDetailBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkScoreResult .accountDetailBox h6 {
  font-size: 18px;
  color: #4e4e4e;
}

.checkScoreResult .accountDetailBox p {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.checkScoreResultCard {
  box-shadow: none !important;
}

.checkScoreResultCard .p-card-body {
  background-color: white !important;
}

.checkScoreResultCard .p-card-content {
  padding: 0px !important;
}

.checkScoreResultCard .p-panel {
  width: 100% !important;
}

.checkScoreResult .numberScore strong {
  font-size: 30px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.checkScoreResult .textScore strong {
  font-size: 30px !important;
  font-weight: 700;
  text-transform: uppercase;
}

.checkScoreText h2 {
  font-size: 30px;
  font-weight: 700;
}

.checkScoreText p {
  font-size: 18px;
}

.checkScoreResultCard .disclaimer p {
  font-size: 18px;
}

.checkScoreModal .p-dialog .p-dialog-content {
  background: none !important;
}

.checkScoreModal .p-dialog .p-dialog-header {
  background: none !important;
}

.checkScoreModal .p-dialog {
  box-shadow: none !important;
}

.checkScoreModal .input-container label {
  font-size: 18px;
  font-weight: 700;
}

.checkScoreModal .checkScoreButton {
  width: auto !important;
}

.checkScoreModal .checkScoreButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.checkScoreModal .input-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.checkScoreModal .input-with-button input {
  width: 75% !important;
}

.checkScoreModal .p-card-body {
  background-color: white !important;
}

.checkScoreModal input {
  background-color: #f5fbfbfb !important;
}

.faq-container {
  display: flex;
  justify-content: space-between;
  padding: 100px;
}

.faq-right {
  width: 750px;
}

.faq-accordion {
  border-bottom: 1px solid black;
  padding-bottom: 20px;
}

.faq-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.faq-accordion-header h4 {
  margin-bottom: 0px !important;
}

.faq-accordion-inner {
  max-height: 0;
  overflow-y: hidden;
  transition: 0.3s ease;
}

.faq-accordion-inner p {
  font-size: 18px;
}

.faq-accordion-inner.active {
  max-height: none;
}

.gig-top {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gig-top h1 {
  font-size: 40px;
  color: #0086bf;
}

.gig-top p {
  font-size: 18px;
}

.gig-top-container {
  width: 960px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gig-top-links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.gig-top-links a {
  text-decoration: underline;
  font-size: 18px;
  color: #0086bf;
}

.gigs {
  display: flex;
  flex-direction: column;
  gap: 250px;
  align-items: center;
}

/* POOR */

.gigs .poor {
  position: relative;
  min-height: 638px;
  width: 100%;
}

.gigs .poor > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
}

.gigs .poor-container {
  position: absolute;
  bottom: 0;
  right: 200px;
  width: 500px;
  min-height: 411px;
  background-color: #0086bf;
}

.gigs .poor-inner {
  position: relative;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gigs .poor-inner h4 {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.gigs .poor-inner p {
  font-size: 20px;
}

/* FAIR */

.gigs .fair {
  position: relative;
  min-height: 638px;
  width: 100%;
}

.gigs .fair > img {
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
}

.gigs .fair-container {
  position: absolute;
  bottom: 20px;
  left: 50px;
  width: 500px;
  min-height: 411px;
  background-color: #0086bf;
}

.gigs .fair-inner {
  position: relative;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gigs .fair-inner h4 {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.gigs .fair-inner p {
  font-size: 18px;
  color: white;
}

/* GOOD */

.gigs .good {
  position: relative;
  min-height: 638px;
  width: 100%;
}

.gigs .good > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
}

.gigs .good-container {
  position: absolute;
  bottom: 50px;
  right: 200px;
  width: 500px;
  min-height: 411px;
  background-color: #0086bf;
}

.gigs .good-inner {
  position: relative;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gigs .good-inner h4 {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.gigs .good-inner p {
  font-size: 18px;
}

/* VERY GOOD */

.gigs .veryGood {
  position: relative;
  min-height: 859px;
  width: 100%;
}

.gigs .veryGood > img {
  position: absolute;
  top: 0;
  right: 0;
  width: 65%;
}

.gigs .veryGood-container {
  position: absolute;
  bottom: 30%;
  left: 200px;
  width: 500px;
  min-height: 411px;
  background-color: #0086bf;
}

.gigs .veryGood-inner {
  position: relative;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gigs .veryGood-inner h4 {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.gigs .veryGood-inner p {
  font-size: 18px;
  color: white;
}

/* GOOD */

.gigs .excellent {
  position: relative;
  min-height: 900px;
  width: 100%;
}

.gigs .excellent > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
}

.gigs .excellent-container {
  position: absolute;
  bottom: 50px;
  right: 200px;
  width: 500px;
  min-height: 411px;
  background-color: #0086bf;
}

.gigs .excellent-inner {
  position: relative;
  padding: 30px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.gigs .excellent-inner h4 {
  font-size: 30px;
  color: white;
  font-weight: 700;
}

.gigs .excellent-inner p {
  font-size: 18px;
  color: white;
}

.secureGuestForm .p-card-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.secureGuestForm .input-container {
  display: flex;
  flex-direction: column;
}

.secureGuestForm .input-container label {
  font-size: 18px;
  font-weight: 700;
}

.secureGuestForm .input-container input {
  font-size: 18px;
  padding-right: 30px !important;
  padding-left: 30px !important;
  border-top-left-radius: 29px;
  border-bottom-left-radius: 29px;
  border: none !important;
  height: 50px !important;
}

.secureGuestForm .p-datepicker-trigger {
  width: 90px;
  border-top-right-radius: 29px;
  border-bottom-right-radius: 29px;
}

@media screen and (max-width: 1000px) {
  .p-card.cardW50 {
    width: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .gigs {
    gap: 100px;
  }

  .poor,
  .fair,
  .good,
  .veryGood,
  .excellent {
    min-height: auto !important;
  }

  /* POOR */
  .gigs .poor > img {
    display: none;
  }

  .gigs .poor-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .gigs .poor-container p {
    color: white !important;
  }

  /* FAIR */

  .gigs .fair > img {
    display: none;
  }

  .gigs .fair-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  /* GOOD */

  .gigs .good > img {
    display: none;
  }

  .gigs .good-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  /* VERY GOOD */

  .gigs .veryGood > img {
    display: none;
  }

  .gigs .veryGood-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  /* EXCELLENT */

  .gigs .excellent > img {
    display: none;
  }

  .gigs .excellent-container {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

@media screen and (max-width: 1400px) {
  .checkScore .p-panel {
    width: 100%;
  }

  .faq-left {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .checkScoreModal.cardW75 {
    width: 100% !important;
  }
  .userAccount .accountBoxLeft {
    width: 100%;
  }
}

@media screen and (max-width: 1700px) {
  .topUpCards {
    justify-content: center;
  }
}

@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1700px !important;
  }
}

@media screen and (max-width: 1300px) {
  .topInfoBarLeft h2 {
    font-size: 20px;
  }

  .topInfoBarLeft p {
    font-size: 14px;
  }

  .topInfoBarRight h4 {
    font-size: 20px;
  }

  .topInfoBarRight p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .topInfoBarRight {
    display: none;
  }

  .topInfoBarLeft h2 {
    font-size: 20px !important;
  }

  .topInfoBarDesktop {
    display: none !important;
  }

  .topInfoBarMobile {
    display: flex !important;
    flex-direction: column;
  }

  .topInfoBarMobileBottom {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .topInfoBarMobileDiv h4 {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0px !important;
  }

  .topInfoBarMobileDiv p {
    font-size: 16px;
    font-weight: 700;
  }
}

.topInfoBarMobile {
  display: none;
}

@media screen and (max-width: 700px) {
  .form-label {
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
  }

  .checkScoreText h2 {
    font-size: 24px;
  }

  .pastReviews .filters > div {
    font-size: 14px;
  }

  .checkScore .p-panel-header {
    font-size: 16px;
  }

  .userAccount .p-panel-header {
    font-size: 16px;
  }

  .checkScoreResult .p-panel-header {
    font-size: 16px;
  }

  .checkScore h2 {
    font-size: 24px;
  }

  .checkscore-action-text div {
    width: 100%;
  }

  .btn {
    font-size: 16px !important;
  }

  .p-card-content {
    padding: 10px;
  }

  .userAccount .accountDetailBox h6 {
    font-size: 16px;
  }

  .userAccount .accountDetailBox p {
    font-size: 18px;
  }

  .userAccount .p-card-body {
    padding: 0;
  }

  .checkScoreResult .accountDetailBox h6 {
    font-size: 16px;
  }

  .checkScoreResult .accountDetailBox p {
    font-size: 18px;
  }

  .checkScoreResult .p-card-body {
    padding: 0;
  }

  .faq-container {
    padding: 0px !important;
  }
}

@media screen and (max-width: 1500px) {
  .checkScoreResultCard.cardW75 {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .dashboardCount {
    height: 86px !important;
    display: flex;
    justify-content: center;
    padding-left: 30px;
  }

  .dashboardCount .title {
    font-size: 16px !important;
  }

  .dashboardCount .value {
    font-size: 20px !important;
  }

  .dashboardCount > .flex {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 700px) {
  .cardContainer {
    padding: 0 !important;
  }

  .loginCard {
    min-width: auto;
    width: 100%;
    box-shadow: none;
    padding: 0 !important;
  }

  .loginCard .p-card-body {
    padding: 0 !important;
    background-color: transparent !important;
  }

  .loginCard .p-card-content {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .loginBody .container-xxl {
    padding: 0;
  }

  .loginCard a span:not(.secondary-a) {
    color: #00bbe1;
    text-decoration: underline;
    font-size: 14px;
  }

  .loginCard a .secondary-a {
    color: black;
    font-size: 14px;
  }

  .loginLeft p {
    font-size: 14px;
  }

  .loginLeft {
    padding: 30px;
    gap: 10px !important;
  }

  .loginLeft h2 {
    font-size: 20px !important;
    margin-top: 50px !important;
  }

  .loginLeft p {
    font-size: 14px !important;
  }

  .signupLeft.mobile {
    display: flex !important;
    width: 100%;
    padding: 30px !important;
  }

  .signupBody .cardContainer {
    padding: 30px !important;
  }

  .signupCard {
    min-width: auto;
    width: 100%;
    box-shadow: none;
    padding: 0 !important;
  }

  .signupCard .p-card-body {
    padding: 0 !important;
    background-color: transparent !important;
  }

  .signupCard .p-card-content {
    padding: 0 !important;
    box-shadow: none !important;
  }

  .signupCard input,
  .loginCard input {
    background-color: #f5fbfb !important;
  }

  .topUpCards {
    justify-content: space-between;
    gap: 30px;
  }

  .topUpCard {
    width: 167px;
    height: 176px;
  }

  .topUpCard p {
    font-size: 14px;
  }

  .topUpCard h4 {
    font-size: 30px;
  }

  .topUpCardButton {
    height: 32px;
    font-size: 16px;
  }

  .topUpCard h5,
  .topUpCard h6 {
    font-size: 16px;
  }

  .gig-top {
    margin-top: 10px !important;
  }

  .gig-top-container h1 {
    font-size: 20px !important;
  }

  .gig-top-container p {
    font-size: 16px !important;
  }

  .gig-top {
    margin-top: 20px !important;
  }

  .gig-top-links a {
    font-size: 12px !important;
  }

  .gigs h4,
  .gigs p {
    font-size: 16px !important;
  }

  .gigs img {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin-bottom: 30px !important;
    display: block !important;
  }

  .faq-container {
    margin-top: 30px;
  }

  .faq-right h1 {
    font-size: 20px;
  }

  .faq-right p {
    font-size: 16px;
  }

  .faq-accordion h4,
  .faq-accordion p {
    font-size: 16px !important;
  }

  .accountBoxRight {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: end;
  }

  .accountBoxRight div {
    width: 100%;
  }

  .bottomButtons button {
    width: 100% !important;
    gap: 10px !important;
  }

  .checkScore h2 {
    text-align: left !important;
    font-size: 16px !important;
    font-weight: normal !important;
    margin-bottom: 30px !important;
  }
}

.userAccount .accountDetailBox {
  align-items: center;
}

.userAccount .accountBoxLeft {
  gap: 50px !important;
}

.signupLeft.mobile {
  display: none;
}

.gig-top {
  margin-top: 5rem;
}
</style>
