'use strict';

export default class TrustdInvitation {

  constructor() {
    this.active = null;
    this.invitationCode = null;
    this.description = null;
    this.channel = null;
  }

  static create(rawData) {
    let data = JSON.parse(JSON.stringify(rawData));
    console.log('data', data);

    const invitation = new TrustdInvitation();
    invitation.active = data.active;
    invitation.invitationCode = data.invitationCode;
    invitation.description = data.description;
    invitation.channel = data.channel;
    return invitation;
  }

}
