'use strict';

import BookingInfo from '@/model/BookingInfo';
import GuestInfo from '@/model/GuestInfo';

export default class GuestFormRequest {

    constructor() {
        this.matchRequestId = null;
        this.guestInfo = null;
        this.bookingInfo = null;
        this.hostEmail = null;
        this.status = null;
    }

    static create(rawData, guestInfo, bookingInfo) {
        const guestFormRequest = new GuestFormRequest();

        // form data
        guestFormRequest.matchRequestId = rawData.matchRequestId;
        guestFormRequest.hostEmail = rawData.hostEmail;
        guestFormRequest.status = rawData.status;

        // guest data
        guestFormRequest.guestInfo = GuestInfo.create(guestInfo);

        // booking data
        guestFormRequest.bookingInfo = BookingInfo.create(bookingInfo);

        return guestFormRequest;
    }

    cleanUpNullFields() {
        this.cleanUpField("matchRequestId");
        this.cleanUpField("guestInfo");
        this.cleanUpField("bookingInfo");
        this.cleanUpField("hostEmail");
        this.cleanUpField("status");
    }

    cleanUpField(field) {
        if(this[field] == null) {
            delete this[field];
        }
    }
}