'use strict';

import { convertToMMYYDDDD } from "@/utils/DateUtils";

export default class GuestInfo {

    constructor() {
        this.PHONE = null;
        this.EMAIL = null;
        this.FIRST_NAME = null;
        this.MIDDLE_NAME = null;
        this.LAST_NAME = null;
        this.DOB = null;
    }

    static create(rawData) {
        const guestInfo = new GuestInfo();

        guestInfo.PHONE = rawData.phone;
        guestInfo.EMAIL = rawData.email;
        guestInfo.FIRST_NAME = rawData.firstName;
        guestInfo.MIDDLE_NAME = rawData.middleName;
        guestInfo.LAST_NAME = rawData.lastName;
        guestInfo.DOB = convertToMMYYDDDD(rawData.dob);

        return guestInfo;
    }

    cleanUpNullFields() {
        this.cleanUpField("PHONE");
        this.cleanUpField("EMAIL");
        this.cleanUpField("FIRST_NAME");
        this.cleanUpField("MIDDLE_NAME");
        this.cleanUpField("LAST_NAME");
        this.cleanUpField("DOB");
    }

    cleanUpField(field) {
        if(this[field] == null) {
            delete this[field];
        }
    }
}