<template>
  <div>
    <Menubar :model="menuItems">
      <template #start>
        <router-link :to="'/'">

<!--          <div class="d-none d-lg-block">-->
<!--            <img alt="Trustd logo" src="../assets/trustd-logo.png" height="40" class="p-mr-1 logo-blend">-->
<!--          </div>-->

<!--          <div class="d-block d-lg-none">-->
<!--            <img alt="Trustd logo" src="../assets/trustd-logo.png" height="40" class="p-mr-1">-->
<!--          </div>-->

        </router-link>
      </template>
      <template #end>
        <!--        <InputText placeholder="Search" type="text"/>-->
        <div v-if="isUserSignedIn">
          <a :href="channelLink" target="_blank">
            <img alt="Channel Logo" :src="channelLogo" height="40" class="p-mr-2">
          </a>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script>
import { hostMenuItems, localHostMenuItems, signedOutMenuItems } from "@/components/NavigationItems";
import { Hub } from "aws-amplify";
import AuthService from "@/service/AmplifyAuth";
import {Channels} from "@/utils/Channels";

export default {
  name: 'MenuNavigation',
  data() {
    let localhost = false;
    const loc = window.location.href;
    if (loc.indexOf('localhost') >= 0) {
      localhost = true;
    }
    return {
      isUserSignedIn: false,
      channelLogo: '',
      channelLink: '',
      menuItems: [],
      isLocalHost: localhost
    }
  },
  mounted() {
    if (this.$store && this.$store.state && this.$store.state.isUserSignedIn) {
      this.isUserSignedIn = this.$store.state.isUserSignedIn;
    }

    this.setMenuItems();
    this.setHubListeners();
  },
  methods: {
    setHubListeners() {
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
            this.isUserSignedIn = true;
            this.setMenuItems();
            break;
          case 'signOut':
            this.isUserSignedIn = false;
            this.setMenuItems();
            break;
        }
      });
    },

    async setMenuItems() {
      console.log('Setting menu items with isUserSignedIn: ' + this.isUserSignedIn)
      this.menuItems = [];
      let items = [];
      const logoServerAddress = 'https://d2gl8sw234uklp.cloudfront.net/channel/'; // todo move into some config

      if (this.isUserSignedIn) {
        items.push(...hostMenuItems);
        if (this.isLocalHost) {
          items.push(...localHostMenuItems);
        }

        const userAttributes = await AuthService.currentAuthenticatedUserAttributes()
        console.log('userAttributes', userAttributes);
        if (userAttributes && userAttributes.channel) {
          this.channelLogo = logoServerAddress + userAttributes.channel + '.png';
          this.channelLink = Channels[userAttributes.channel];
        } else {
          this.channelLogo = logoServerAddress + 'trustd.png'; // this should not happen at all just in case
          this.channelLink = Channels["trustd"];
        }

      } else {
        items.push(...signedOutMenuItems);
      }
      this.menuItems.push(...items);
    },
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.subTitle {
  font-size: 2rem;
  padding-top: 3px;
  height: 40px;
  display: inline-flex;
  vertical-align: bottom;
  color: #0085BF;
}

/*
Modifications from Ben
 */

.p-menubar {
  padding: 20px;
}

.p-submenu-list {
  z-index: 10;
}

.logo-blend {
  mix-blend-mode:multiply;
}

</style>