'use strict';

const VALID_EMAIL_FORMAT = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const isValid = (inEmailAddress) => {
  const valid = VALID_EMAIL_FORMAT.test(inEmailAddress);
  console.log(inEmailAddress + ' valid? ' + valid);
  return valid;
}


module.exports = {
  isValid,
}