'use strict';

const parseError = (error) => {
  let msg = null;

  if (error) {
    console.error('parsing error message: ', error);
    if (error.message || error.response) {

      if (error.message) {
        msg = error.message;
      }

      if (error.response) {
        if (error.response.data) {
          if (msg) {
            msg = msg + ': ' + error.response.data;
          } else {
            msg = error.response.data;
          }
        } else {
          if (msg) {
            msg = msg + ': ' + error.response;
          } else {
            msg = error.response;
          }
        }
      }

    } else if (error.error) {
      msg = parseError(error.error);
    } else {
      msg = error;
    }
  }

  return msg;
}

module.exports = {
  parseError,
}
