import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import HostLogin from "@/views/HostLogin";
import SignUp from "@/views/SignUp";
import GuestForm from "@/views/GuestForm";
import InvitationCode from "@/views/InvitationCode";
import AppLayout from "@/layout/AppLayout.vue";

const routes = [
  // unguarded routes

  {
    path: "/invitation-code",
    name: "InvitationCode",
    component: InvitationCode,
  },
  {
    path: "/login",
    name: "Login",
    component: HostLogin,
  },
  {
    path: "/signupcode/:code",
    name: "SignupWithCode",
    component: SignUp,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignUp,
  },
  {
    path: "/guestForm/:id",
    name: "GuestForm",
    component: GuestForm,
    props: true,
  },
  {
    // i.e. http://localhost:5000/q/62c3335baa2a1eaabb822dbf or https://tdp.trustd.ai/qsr/62c3335baa2a1eaabb822dbf
    path: "/q/:reference",
    name: "QuickScoreResultsDirect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QuickScoreResults.vue"),
  },
  {
    path: "/quickscoreresults",
    name: "QuickScoreResults",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QuickScoreResults.vue"),
  },
  // guarded routes
  {
    path: "/",
    name: "Dashboard",
    component: AppLayout,
    children: [
      {
        path: "/",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Dashboard.vue"),
      },
      {
        path: "/home",
        name: "HomeDefault",
        component: () =>
          import(/* webpackChunkName: "home" */ "../viewslocal/TestDashboard"),
      },
      {
        path: "/authentication",
        name: "Authentication",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../viewslocal/AwsAuthentication"
          ),
      },
      {
        path: "/account",
        name: "Account",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/UserAccount.vue"),
      },
      {
        path: "/topup",
        name: "TopUp",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/TopUp.vue"),
      },
      {
        path: "/faq",
        name: "FAQ",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/FAQ.vue"),
      },
      {
        path: "/gig",
        name: "GIG",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/GIG.vue"),
      },
      {
        path: "/password",
        name: "Password",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ChangePassword"),
      },
      {
        path: "/userinfo",
        name: "UserInfo",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/UserInfo.vue"),
      },
      {
        path: "/checkscore",
        name: "CheckScore",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CheckScore.vue"),
      },
      {
        path: "/reviews",
        name: "Reviews",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/PastReviews.vue"),
      },
      {
        path: "/guestFormRequests",
        name: "GuestFormRequests",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/PastGuestFormRequests.vue"
          ),
      },
      {
        path: "/datastore",
        name: "DataStore",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../viewslocal/DataStore.vue"),
      },
      {
        path: "/restapi",
        name: "RestApi",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../viewslocal/RestApi.vue"),
      },
      {
        path: "/storage",
        name: "Storage",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../viewslocal/TestStorage"),
      },
      // localhost routes
      {
        path: "/chartsample",
        name: "ChartSample",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../viewslocal/ChartSample.vue"
          ),
      },
      {
        path: "/componenttest",
        name: "ComponentTest",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../viewslocal/ComponentTest.vue"
          ),
      },
      {
        path: "/envvariables",
        name: "EnvVariables",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../viewslocal/EnvVariables.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isSignedIn = store.state.isUserSignedIn;
  let payload = localStorage.getItem("asdasd123123dfgdfgqweqwe");
  const isGuardNeeded = openViews.indexOf(to.name) < 0;

  console.log("payload user: ", payload);

  if (payload !== undefined && payload !== null) {
    payload = true;
  }

  console.log(!(isSignedIn || payload) && isGuardNeeded);

  if (!(isSignedIn || payload) && isGuardNeeded) {
    console.error(
      "not logged in yet, view is guarded, login required: ",
      to.name
    );
    next({ name: "Login" });
  } else {
    next();
  }
});

const openViews = [
  "Login",
  "Signup",
  "SignupWithCode",
  "GuestForm",
  "QuickScoreResults",
  "QuickScoreResultsDirect",
  "InvitationCode",
];

export default router;
