'use strict';

export default class TrustdPayload {

  constructor() {
    this._email = null;
    this._phone = null;
    this._firstName = null;
    this._middleName = null;
    this._lastName = null;
    this._birthDate = null;
    this._checkInDate = null;
    this._checkOutDate = null;
    this._listingName = null;
    this._listingChannel = null;
  }

  static create(raw) {
    const payload = new TrustdPayload();

    payload.email = raw.guest_email;
    payload.phone = raw.phone;
    payload.firstName = raw.first_name;
    payload.middleName = raw.middle_name;
    payload.lastName = raw.last_name;
    payload.birthDate = raw.dob;
    payload.checkInDate = raw.check_in_date;
    payload.checkOutDate = raw.check_out_date;
    payload.listingName = raw.listing_name;
    payload.listingChannel = raw.listing_channel;

    return payload;
  }


  get email() {
    return this._email;
  }

  set email(value) {
    this._email = value;
  }

  get phone() {
    return this._phone;
  }

  set phone(value) {
    this._phone = value;
  }

  get firstName() {
    return this._firstName;
  }

  set firstName(value) {
    this._firstName = value;
  }

  get middleName() {
    return this._middleName;
  }

  set middleName(value) {
    this._middleName = value;
  }

  get lastName() {
    return this._lastName;
  }

  set lastName(value) {
    this._lastName = value;
  }

  get birthDate() {
    return this._birthDate;
  }

  set birthDate(value) {
    this._birthDate = value;
  }

  get checkInDate() {
    return this._checkInDate;
  }

  set checkInDate(value) {
    this._checkInDate = value;
  }

  get checkOutDate() {
    return this._checkOutDate;
  }

  set checkOutDate(value) {
    this._checkOutDate = value;
  }

  get listingName() {
    return this._listingName;
  }

  set listingName(value) {
    this._listingName = value;
  }

  get listingChannel() {
    return this._listingChannel;
  }

  set listingChannel(value) {
    this._listingChannel = value;
  }
}